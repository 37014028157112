export const status = {
  NOT_DONE: "no_done",
  VALIDATED: "validated",
  APPROUVED: "waiting",
  REFUSED: "refused"
};

export const isNotDone = (value: string) => {
  return value === status.NOT_DONE;
};

export const isValidated = (value: string) => {
  return value === status.VALIDATED;
};

export const isApprouved = (value: string) => {
  return value === status.APPROUVED;
};

export const isRefused = (value: string) => {
  return value === status.REFUSED;
};
