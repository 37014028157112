import { v4 as uuidv4 } from "uuid";
import * as actions from "./actionTypes";
import {
  CreateMissionOpts,
  UpdateMissionOpts,
  Mission
} from "../../models/Interfaces/Mission";
import { getSiteDispatch } from "../Site/actions";
import { _refreshAccessToken } from "../../utils/Session";

const BACKEND_URL = process.env.REACT_APP_API_BACKEND_URL;

export const createMissionDispatch = (payload: CreateMissionOpts) => {
  return (dispatch, getState) => {
    const token = getState().authentication.token;
    return dispatch(createMission(payload, token)).then(() => {
      // fetch site after created mission
      return dispatch(getSiteDispatch({ id: payload.site.id }));
    });
  };
};

const createMission = (
  payload: CreateMissionOpts,
  token: string
): actions.CreateMissionAction => {
  const body = { ...payload };

  // site
  const site = JSON.stringify({ id: payload.site.id });

  //  delete site
  delete body.site;
  body.site = JSON.parse(site);

  const tempId = uuidv4();

  return {
    type: actions.CREATE_MISSION,
    payload: { mission: payload, localId: tempId },
    meta: {
      offline: {
        effect: {
          url: `${BACKEND_URL}/mission`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`
          },
          refreshAccessToken: _refreshAccessToken,
          json: body
        },
        commit: {
          type: actions.CREATE_MISSION_SUCCESS,
          meta: { localId: tempId }
        },
        rollback: {
          type: actions.CREATE_MISSION_FAILURE,
          meta: { localId: tempId }
        }
      }
    }
  };
};

export const updateMissionDispatch = (
  payload: UpdateMissionOpts,
  mission: Mission
) => {
  return (dispatch, getState) => {
    const token = getState().authentication.token;
    return dispatch(updateMission(payload, mission, token)).then(() => {
      // fetch site after update mission
      return dispatch(getSiteDispatch({ id: payload.site.id }));
    });
  };
};

const updateMission = (
  payload: UpdateMissionOpts,
  mission: Mission,
  token: string
): actions.UpdateMissionAction => {
  const body = { ...payload };

  // site
  const site = JSON.stringify({ id: payload.site.id });

  //  delete site
  delete body.site;
  body.site = JSON.parse(site);

  return {
    type: actions.UPDATE_MISSION,
    payload,
    meta: {
      offline: {
        effect: {
          url: `${BACKEND_URL}/mission/${payload.id}`,
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`
          },
          refreshAccessToken: _refreshAccessToken,
          json: body
        },
        commit: {
          type: actions.UPDATE_MISSION_SUCCESS,
          meta: { id: payload.id, mission: mission }
        },
        rollback: {
          type: actions.UPDATE_MISSION_FAILURE,
          meta: { id: payload.id, mission: mission }
        }
      }
    }
  };
};
