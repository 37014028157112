import {
  ListWorkerOpts,
  GetWorkerOpts,
  Worker,
  CreateWorkerOpts,
  UpdateWorkerOpts
} from "../../models/Interfaces/Worker";
import { Site } from "../../models/Interfaces/Site";
import { ErrorResponse } from "../../api/error";
import {
  CreateControlOpts,
  Control,
  UpdateControlOpts,
  DeleteControlOpts
} from "../../models/Interfaces/Control";

export const GET_WORKERS = "GET_WORKERS";
export interface GetWorkersAction {
  type: typeof GET_WORKERS;
  payload: ListWorkerOpts;
  meta: any;
}

export const GET_WORKERS_SUCCESS = "GET_WORKERS_SUCCESS";
export interface GetWorkersSuccessAction {
  type: typeof GET_WORKERS_SUCCESS;
  payload: Worker[];
  meta: { site: Site };
}

export const GET_WORKERS_FAILURE = "GET_WORKERS_FAILURE";
export interface GetWorkersFailureAction {
  type: typeof GET_WORKERS_FAILURE;
  payload: { status: number; name: string; response: ErrorResponse };
  meta: { site: Site };
}

export const GET_WORKER = "GET_WORKER";
export interface GetWorkerAction {
  type: typeof GET_WORKER;
  payload: GetWorkerOpts;
  meta: any;
}

export const GET_WORKER_SUCCESS = "GET_WORKER_SUCCESS";
export interface GetWorkerSuccessAction {
  type: typeof GET_WORKER_SUCCESS;
  payload: Worker;
  meta: any;
}

export const GET_WORKER_FAILURE = "GET_WORKER_FAILURE";
export interface GetWorkerFailureAction {
  type: typeof GET_WORKER_FAILURE;
  payload: { status: number; name: string; response: ErrorResponse };
  meta: any;
}

export const CREATE_WORKER = "CREATE_WORKER";
export interface CreateWorkerAction {
  type: typeof CREATE_WORKER;
  payload: { worker: CreateWorkerOpts; localId: string };
  meta: any;
}

export const CREATE_WORKER_SUCCESS = "CREATE_WORKER_SUCCESS";
export interface CreateWorkerSuccessAction {
  type: typeof CREATE_WORKER_SUCCESS;
  payload: Worker;
  meta: any;
}

export const CREATE_WORKER_FAILURE = "CREATE_WORKER_FAILURE";
export interface CreateWorkerFailureAction {
  type: typeof CREATE_WORKER_FAILURE;
  payload: { status: number; name: string; response: ErrorResponse };
  meta: any;
}

export const UPDATE_WORKER = "UPDATE_WORKER";
export interface UpdateWorkerAction {
  type: typeof UPDATE_WORKER;
  payload: UpdateWorkerOpts;
  meta: any;
}

export const UPDATE_WORKER_SUCCESS = "UPDATE_WORKER_SUCCESS";
export interface UpdateWorkerSuccessAction {
  type: typeof UPDATE_WORKER_SUCCESS;
  payload: Worker;
  meta: { id: string; worker: Worker };
}

export const UPDATE_WORKER_FAILURE = "UPDATE_WORKER_FAILURE";
export interface UpdateWorkerFailureAction {
  type: typeof UPDATE_WORKER_FAILURE;
  payload: { status: number; name: string; response: ErrorResponse };
  meta: { id: string; worker: Worker };
}

export const CREATE_CONTROL = "CREATE_CONTROL";
export interface CreateControlAction {
  type: typeof CREATE_CONTROL;
  payload: { control: CreateControlOpts; localId: string };
  meta: any;
}

export const CREATE_CONTROL_SUCCESS = "CREATE_CONTROL_SUCCESS";
export interface CreateControlSuccessAction {
  type: typeof CREATE_CONTROL_SUCCESS;
  payload: Control;
  meta: any;
}

export const CREATE_CONTROL_FAILURE = "CREATE_CONTROL_FAILURE";
export interface CreateControlFailureAction {
  type: typeof CREATE_CONTROL_FAILURE;
  payload: { status: number; name: string; response: ErrorResponse };
  meta: any;
}

export const UPDATE_CONTROL = "UPDATE_CONTROL";
export interface UpdateControlAction {
  type: typeof UPDATE_CONTROL;
  payload: UpdateControlOpts;
  meta: any;
}

export const UPDATE_CONTROL_SUCCESS = "UPDATE_CONTROL_SUCCESS";
export interface UpdateControlSuccessAction {
  type: typeof UPDATE_CONTROL_SUCCESS;
  payload: Control;
  meta: { id: string; control: Control };
}

export const UPDATE_CONTROL_FAILURE = "UPDATE_CONTROL_FAILURE";
export interface UpdateControlFailureAction {
  type: typeof UPDATE_CONTROL_FAILURE;
  payload: { status: number; name: string; response: ErrorResponse };
  meta: { id: string; control: Control };
}

export const DELETE_CONTROL = "DELETE_CONTROL"
export interface DeleteControlAction {
  type: typeof DELETE_CONTROL
  payload: DeleteControlOpts
  meta: any
}

export const DELETE_CONTROL_SUCCESS = "DELETE_CONTROL_SUCCESS"
export interface DeleteControlSuccess {
  type: typeof DELETE_CONTROL_SUCCESS
  payload: any
  meta: { id: string; control: Control };
}

export const DELETE_CONTROL_FAILURE = "DELETE_CONTROL_FAILURE"
export interface DeleteControlFailure {
  type: typeof DELETE_CONTROL_FAILURE
  payload: { status: number; name: string; response: ErrorResponse }
  meta: { id: string; control: Control }
}

export type WorkerAction =
  | GetWorkersAction
  | GetWorkersSuccessAction
  | GetWorkersFailureAction
  | GetWorkerAction
  | GetWorkerSuccessAction
  | GetWorkerFailureAction
  | CreateWorkerAction
  | CreateWorkerSuccessAction
  | CreateWorkerFailureAction
  | UpdateWorkerAction
  | UpdateWorkerSuccessAction
  | UpdateWorkerFailureAction
  | CreateControlAction
  | CreateControlSuccessAction
  | CreateControlFailureAction
  | UpdateControlAction
  | UpdateControlSuccessAction
  | UpdateControlFailureAction
  | DeleteControlAction
  | DeleteControlSuccess
  | DeleteControlFailure
