import {
  Mission,
  CreateMissionOpts,
  UpdateMissionOpts
} from "../../models/Interfaces/Mission";
import { ErrorResponse } from "../../api/error";

export const CREATE_MISSION = "CREATE_MISSION";
export interface CreateMissionAction {
  type: typeof CREATE_MISSION;
  payload: { mission: CreateMissionOpts; localId: string };
  meta: any;
}

export const CREATE_MISSION_SUCCESS = "CREATE_MISSION_SUCCESS";
export interface CreateMissionSuccessAction {
  type: typeof CREATE_MISSION_SUCCESS;
  payload: Mission;
  meta: any;
}

export const CREATE_MISSION_FAILURE = "CREATE_MISSION_FAILURE";
export interface CreateMissionFailureAction {
  type: typeof CREATE_MISSION_FAILURE;
  payload: { status: number; name: string; response: ErrorResponse };
  meta: any;
}

export const UPDATE_MISSION = "UPDATE_MISSION";
export interface UpdateMissionAction {
  type: typeof UPDATE_MISSION;
  payload: UpdateMissionOpts;
  meta: any;
}

export const UPDATE_MISSION_SUCCESS = "UPDATE_MISSION_SUCCESS";
export interface UpdateMissionSuccessAction {
  type: typeof UPDATE_MISSION_SUCCESS;
  payload: Mission;
  meta: any;
}

export const UPDATE_MISSION_FAILURE = "UPDATE_MISSION_FAILURE";
export interface UpdateMissionFailureAction {
  type: typeof UPDATE_MISSION_FAILURE;
  payload: { status: number; name: string; response: ErrorResponse };
  meta: any;
}

export type MissionAction =
  | CreateMissionAction
  | CreateMissionSuccessAction
  | CreateMissionFailureAction
  | UpdateMissionAction
  | UpdateMissionSuccessAction
  | UpdateMissionFailureAction;
