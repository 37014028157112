import React from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import AppRoutes from "./routes";

export const history = createBrowserHistory();



const App = () => {
  return <Router history={history}>
    <AppRoutes />
  </Router>;
};

export default App;
