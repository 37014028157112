import React from "react"
import { MessageFormatElement } from 'intl-messageformat-parser';
import classNames from "classnames"

interface Props {
  children?: React.ReactNode
  title?: string | MessageFormatElement[];
  icon?: React.ReactNode;
  shadow?: boolean;
  className?: string;
}

const Header = ({ children, title, icon, shadow, className }: Props) => {
  let headerClassName = classNames("flex flex-col z-2", className)
  if (shadow) headerClassName = classNames(headerClassName, "shadow-sm")

  return (
    <div className={headerClassName} style={{ padding: "20px" }}>
      <div className="flex flex-row">
        <div className="flex-1">
          {title && (
            <span className="inline-block w-full text-15px text-center tracking-016px text-socotec-gray-100 font-roboto">
              {title}
            </span>
          )}
          {!title && children}
        </div>
        {icon}
      </div>
      {title && children}
    </div>
  )
}

export default Header