import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { injectIntl, useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import { AppState } from "../../redux/reducers";
import * as siteActions from "../../redux/Site/actions";
import * as workerActions from "../../redux/Worker/actions";
import * as missionActions from "../../redux/Mission/actions";
import { Worker } from "../../models/Interfaces/Worker";
import { showErrorNotification } from "../../utils/Notification";
import {
  getNotControlledWorkers,
  getApprouvedWorkers,
  getCheckedWorkers,
  getRefusedWorkers
} from "../../utils/Workers";
import { Header, ButtonHome, State } from "../../components/General";
import Menu from "../../components/Menu";
import Button from "../../components/Button";
import ModalCreateWorker from "../../components/Modals/Worker/New";
import ModalMissionEnd from "../../components/Modals/Mission/End";
import ModalComment from "../../components/Modals/Comment";
import { status as ControlStatus } from "../../enums/control";
import MissionStatus from "../../enums/mission";
import images from "../../images";
import "./style.css";
import { useThunkDispatch } from "../../redux/hooks";
import OfflineDetector from "../../services/OfflineDetector";

const Mission = () => {
  const [workersNotDone, setWorkersNotDone] = useState([] as Worker[]);
  const [workersChecked, setWorkersChecked] = useState([] as Worker[]);
  const [workersApprouved, setWorkersApprouved] = useState([] as Worker[]);
  const [workersRefused, setWorkersRefused] = useState([] as Worker[]);
  const [showModalCreateWorker, setShowModalCreateWorker] = useState(false);
  const [showModalComment, setShowModalComment] = useState(false);
  const [showModalEndMission, setShowModalEndMission] = useState(false);

  const { id, mid } = useParams();
  const dispatch = useThunkDispatch();
  const history = useHistory();
  const intl = useIntl();

  const { site, workers } = useSelector((state: AppState) => ({
    site: state.site.site,
    workers: state.worker.workers
  }));

  useEffect(() => {
    handleGetSite();
    return () => { };
  }, []);

  const handleGetSite = () => {
    if (id) {
      dispatch(siteActions.getSiteDispatch({ id: id }))
        .then(res => { })
        .catch(err => {
          showErrorNotification(
            "Une erreur s'est produite lors du chargement du chantier"
          );
        });
    }
  };

  useEffect(() => {
    handleListWorkers();
  }, []);

  const handleListWorkers = () => {
    if (id) {
      const site = {
        id: id
      };

      if (OfflineDetector.appIsOnline()) {
        dispatch(workerActions.getWorkersDispatch({ site: site }))
          .then(() => { })
          .catch((err: any) => {
            showErrorNotification(
              "Une erreur s'est produite lors du chargement des travailleurs"
            );
          });
      } else {
        dispatch(workerActions.getWorkersDispatch({ site: site }));
      }
    }
  };

  useEffect(() => {
    if (workers) {
      const workersNotDone = getNotControlledWorkers(workers, mid);

      const workersChecked = getCheckedWorkers(workers, mid);

      const workersApprouved = getApprouvedWorkers(workers, mid);

      const workersRefused = getRefusedWorkers(workers, mid);

      setWorkersNotDone(workersNotDone);
      setWorkersChecked(workersChecked);
      setWorkersApprouved(workersApprouved);
      setWorkersRefused(workersRefused);
    }
  }, [workers, mid]);

  let mission;
  if (site && mid && site.missions && !_.isEmpty(site.missions)) {
    mission = site.missions.find(mission => mission.id == mid);
  }

  const handleEndMission = () => {
    const payload = {
      id: mission.id,
      status: MissionStatus.CLOSED,
      site: site
    };
    if (OfflineDetector.appIsOnline()) {
      dispatch(missionActions.updateMissionDispatch(payload, mission))
        .then(() => {
          setShowModalEndMission(false)
        })
        .catch((err: any) => {
          showErrorNotification("Impossible de valider une mission");
        });
    } else {
      dispatch(missionActions.updateMissionDispatch(payload, mission));
      setShowModalEndMission(false)
    }
  };

  const handleShowModalCreateWorker = () => {
    setShowModalCreateWorker(!showModalCreateWorker);
  };

  const handleShowModalComment = () => {
    setShowModalComment(!showModalComment);
  };

  const handleShowModalEndMission = () => {
    setShowModalEndMission(!showModalEndMission);
  };

  const isMissionClosed = mission?.status == MissionStatus.CLOSED;

  const pathSiteMission = "/site/" + id + "/" + mid;

  const IconHeader = (
    <ButtonHome
      onClick={() => {
        history.push("/dashboard");
      }}
    />
  );

  const containerStyle = isMissionClosed ? {} : { paddingBottom: "85px" };

  return (
    <div
      className="flex flex-col w-full min-h-screen bg-socotec-white-100"
      style={containerStyle}
    >
      {!isMissionClosed && (
        <>
          {site && (
            <div className="flex flex-col sticky bg-white" style={{ marginBottom: "20px" }}>
              <Header
                title={site.name}
                icon={IconHeader}
                shadow
              >
                <State stateClassName="bg-socotec-green-100">
                  <span className="text-13px">{intl.messages["MissionPage.inprogress"]}</span>
                </State>
              </Header>
            </div>
          )}

          <div>
            <div className="mb-6" style={{ padding: "0 20px" }}>
              <div className="font-bold text-18px text-socotec-gray-100 mb-4">
                {intl.messages["MissionPage.title.workersDeclared"]}{" "}
                {`(${workers.length})`}
              </div>
              <div className="flex flex-row">
                <Button
                  theme="secondary"
                  className="flex flex-row justify-center items-center text-18px"
                  onClick={() => {
                    history.push(pathSiteMission + "/workers");
                  }}
                >
                  {intl.messages["MissionPage.button.search"]}{" "}
                  <img src={images.search} alt="" className="ml-2" />
                </Button>
                <div style={{ width: "20px" }}></div>
                <Button
                  theme="secondary"
                  className="flex flex-row justify-center items-center text-18px"
                  onClick={() => {
                    handleShowModalCreateWorker();
                  }}
                >
                  {intl.messages["MissionPage.button.add"]}{" "}
                  <img src={images.plus} alt="+" className="ml-2" />
                </Button>
              </div>
            </div>

            <div
              className="flex flex-row justify-between bg-white text-socotec-gray-100 text-18px p-4 mb-4 border-t-2 border-b-2 border-socotec-gray-300 border-solid"
              onClick={() => {
                history.push({
                  pathname: pathSiteMission + "/workers",
                  search: "q=" + ControlStatus.NOT_DONE,
                  state: { transition: "slideLeft" }
                });
              }}
            >
              <span>{intl.messages["MissionPage.list.workersDeclared"]}</span>
              <span className="font-bold">{workersNotDone.length}</span>
              <img src={images.arrowRight} alt=">" />
            </div>

            <div
              className="flex flex-row justify-between bg-white text-socotec-gray-100 text-18px p-4 border-t-2 border-socotec-gray-300 border-solid"
              onClick={() => {
                history.push({
                  pathname: pathSiteMission + "/workers",
                  search: "q=" + ControlStatus.VALIDATED,
                  state: { transition: "slideLeft" }
                });
              }}
            >
              <span className="flex-1">
                {intl.messages["MissionPage.control.validated"]}
              </span>
              <span className="text-socotec-green-200 font-bold mr-8">
                {workersChecked.length}
              </span>
              <img src={images.arrowRight} alt=">" />
            </div>
            <div
              className="flex flex-row justify-between bg-white text-socotec-gray-100 text-18px p-4 border-t-2 border-socotec-gray-300 border-solid"
              onClick={() => {
                history.push({
                  pathname: pathSiteMission + "/workers",
                  search: "q=" + ControlStatus.APPROUVED,
                  state: { transition: "slideLeft" }
                });
              }}
            >
              <span className="flex-1">
                {intl.messages["MissionPage.control.approuved"]}
              </span>
              <span className="text-socotec-orange-100 font-bold mr-8">
                {workersApprouved.length}
              </span>
              <img src={images.arrowRight} alt=">" />
            </div>
            <div
              className="flex flex-row justify-between bg-white text-socotec-gray-100 text-18px p-4 mb-4 border-t-2 border-b-2 border-socotec-gray-300 border-solid"
              onClick={() => {
                history.push({
                  pathname: pathSiteMission + "/workers",
                  search: "q=" + ControlStatus.REFUSED,
                  state: { transition: "slideLeft" }
                });
              }}
            >
              <span className="flex-1">
                {intl.messages["MissionPage.control.refused"]}
              </span>
              <span className="text-socotec-red-100 font-bold mr-8">
                {workersRefused.length}
              </span>
              <img src={images.arrowRight} alt=">" />
            </div>

            <div
              className="flex flex-row justify-between bg-white text-socotec-gray-100 text-18px p-4 mb-8 border-t-2 border-b-2 border-socotec-gray-300 border-solid"
              onClick={handleShowModalComment}
            >
              <span>{intl.messages["MissionPage.mission.comment"]}</span>
              <img src={images.arrowRight} alt=">" />
            </div>

            <div className="text-center text-socotec-blue-100 text-16px font-roboto pb-6">
              <span onClick={handleShowModalEndMission}>
                {intl.messages["MissionPage.mission.end"]}
              </span>
            </div>
          </div>

          <Menu site={site}></Menu>
        </>
      )}

      {isMissionClosed && (
        <div className="flex flex-col justify-center flex-grow text-socotec-gray-100 px-4" style={{ paddingBottom: "80px" }}>
          <div className="flex-1">
            <div
              className="flex flex-col items-center pt-12 mb-2 mx-auto text-center"
              style={{ maxWidth: "200px" }}
            >
              <img src={images.done} alt="" />
              <div className="text-18px font-bold leading-21px mb-1">
                {intl.messages["MissionPage.rapport.title"]}
              </div>
              <div className="text-15px">{site?.name}</div>
            </div>
            <div className="barre-vertical py-4 mb-2"></div>
            <div className="flex flex-col items-center mb-2">
              <div className="circle-number border-2 border-solid border-socotec-blue-100 text-socotec-blue-100 font-bold text-18px">
                2
              </div>
              <div
                className="text-15px text-center"
                style={{ maxWidth: "240px" }}
              >
                {intl.messages["MissionPage.rapport.2"]}
              </div>
            </div>
            <div className="barre-vertical py-4 mb-2"></div>
            <div className="flex flex-col items-center mb-2">
              <div className="circle-number border-2 border-solid border-socotec-blue-100 text-socotec-blue-100 font-bold text-18px">
                3
              </div>
              <div
                className="text-15px text-center"
                style={{ maxWidth: "240px" }}
              >
                {intl.messages["MissionPage.rapport.3"]}
              </div>
            </div>
            <div className="barre-vertical py-4 mb-2"></div>
            <div className="flex flex-col items-center">
              <div className="circle-number border-2 border-solid border-socotec-blue-100 text-socotec-blue-100 font-bold text-18px">
                4
              </div>
              <div
                className="text-15px text-center"
                style={{ maxWidth: "240px" }}
              >
                {intl.messages["MissionPage.rapport.4"]}
              </div>
            </div>
          </div>
          <div className="fixed bottom-20px left-10 right-10 bg-socotec-white-100" style={{ width: "calc(100% - 5rem)" }}>
            <Button
              theme="info"
              onClick={() => {
                history.push("/dashboard");
              }}
            >
              <span className="font-roboto">
                {intl.messages["MissionPage.button.listeVisites"]}
              </span>
            </Button>
          </div>
        </div>
      )}

      {id && site && mission && (
        <ModalCreateWorker
          isOpen={showModalCreateWorker}
          onClick={handleShowModalCreateWorker}
          site={{ id: id, companies: site.companies }}
          mission={mission}
        />
      )}

      {site && mission && (
        <ModalComment
          isOpen={showModalComment}
          onClick={handleShowModalComment}
          site={site}
          mission={mission}
        />
      )}

      {site && mission && (
        <ModalMissionEnd
          isOpen={showModalEndMission}
          onClick={handleShowModalEndMission}
          site={site}
        >
          <div className="flex flex-col pt-3 mb-4">
            <Button onClick={handleEndMission}>
              <span className="font-roboto">
                {intl.messages["MissionPage.button.transfer"]}
              </span>
            </Button>
          </div>
        </ModalMissionEnd>
      )}
    </div>
  );
};

export default injectIntl(Mission);
