export const getContactRole = role => {
  switch (role) {
    case "client":
      return "MOA";
    case "project_manager":
      return "MOE";
    case "scheduler":
      return "OPC";
    case "coordinator":
      return "CSPS";
    case "headmaster":
      return "Chef Ets";
    default:
      return "";
  }
}
