import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { injectIntl, useIntl } from "react-intl";
import _ from "lodash";
import { Formik } from "formik";
import * as Yup from "yup";
import images from "../../images";
import * as siteActions from "../../redux/Site/actions";
import Button from "../../components/Button";
import ModalRecipients from "../../components/Modals/Recipients";
import { showErrorNotification } from "../../utils/Notification";
import { Contact } from "../../models/Interfaces/Contact";
import { AppState } from "../../redux/reducers";
import { useThunkDispatch } from "../../redux/hooks";

interface Props { }

const initialValues = {
  message: ""
};

const Report = (props: Props) => {
  const [showModalRecipients, setShowModalRecipients] = useState(false);
  const [recipients, setRecipients] = useState([] as Contact[]);

  const dispatch = useThunkDispatch();
  const history = useHistory();
  const location = useLocation();
  const intl = useIntl();
  const { id, mid } = useParams();




  const validationSchema = Yup.object({
    message: Yup.string().required("Veuillez saisir un message")
  });

  // pull out state from our store
  const { site, error } = useSelector((state: AppState) => ({
    site: state.site.site,
    error: state.site.error
  }));

  useEffect(() => {

    handleGetSite();
    return () => {

    };
  }, []);

  useEffect(() => {

    if (site) {

      if (mid) {
        const mission = site.missions?.find(e => e.id == mid);

      }

      const principles = site
        ? site.contacts?.filter(e => e.principle === true)
        : [];

      const user = localStorage.getItem("user");
      const me = user ? JSON.parse(user) : null;

      let recipients = [] as Contact[];
      if (me) {
        recipients.push(me);
      }
      if (principles) {
        recipients = recipients.concat(principles);
      }

      setRecipients(recipients);
    }
  }, [site]);

  const handleGetSite = () => {
    if (id) {


      dispatch(siteActions.getSiteDispatch({ id: id }))
        .then(res => {

        })
        .catch(err => {

          showErrorNotification(
            "Une erreur s'est produite lors du chargement du chantier"
          );
        });
    }
  };

  const handleShowModalRecipients = () => {
    setShowModalRecipients(!showModalRecipients);
  };

  const principles = site
    ? site.contacts?.filter(e => e.principle === true)
    : [];

  const contacts = site ? site.contacts?.filter(e => e.principle !== true) : [];

  const handleSendReport = (
    values: {
      message: string;
    },
    setSubmitting: any
  ) => {


    if (site && id) {
      const mission = site.missions?.find(e => e.id == mid);

      if (mission) {
        const payload = {
          site: site,
          mission: mission,
          recipients: recipients,
          message: values.message
        };


        history.push(`/report/${id}/${mid}/confirmation`);
      }
    }
  };

  const handleCheckRecipient = e => {
    const checked = e.target.checked;
    const id = e.target.id;

    const contact = contacts?.find(e => e.id == id);

    const index = recipients.findIndex(c => c.id === id);
    if (index > -1) {
      if (!checked) {
        // we will remove it
        const items = recipients.filter(e => e.id !== id);
        setRecipients(items);
      }
    } else if (checked) {
      const clone = Object.assign({}, contact);
      clone.checked = checked;

      // no found in recipients, we will add this contact
      const items = [...recipients];
      items.push(clone);

      setRecipients(items);
    }
  };

  return (
    <div className="flex flex-col w-full min-h-screen bg-socotec-white-100">
      <div className="flex flex-row items-center justify-between px-4 py-4">
        <img
          src={images.retour}
          alt="Retour"
          onClick={() => {
            history.push(`/site/${id}/${mid}`);
          }}
        />
        {contacts && contacts.length > 0 && (
          <span
            className="font-roboto text-socotec-blue-100 text-16px opacity-100 tracking-016px"
            onClick={handleShowModalRecipients}
          >
            {intl.messages["ReportPage.add"]}
          </span>
        )}
      </div>
      <div className="flex flex-col px-4">
        <span className="font-roboto font-bold text-socotec-gray-100 text-20px opacity-100 tracking-normal">
          {intl.messages["ReportPage.recipients"]}
        </span>
      </div>
      <div className="flex flex-row flex-wrap px-4 pt-4 pb-6">
        <span className="font-roboto text-16px text-center text-white opacity-100 bg-socotec-gray-100 rounded-20px py-2 px-4 mr-2 my-1">
          {intl.messages["ReportPage.me"]}
        </span>
        {principles &&
          principles.map((contact, index) => {
            return (
              <div
                key={index}
                className="flex flex-row bg-socotec-gray-100 rounded-20px py-2 px-4 mr-2 my-1"
              >
                <img src={images.stars} alt="*" />
                <span className="font-roboto text-16px text-center text-white opacity-100 ml-2">
                  {contact.firstname} {contact.lastname}
                </span>
              </div>
            );
          })}
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleSendReport(values, setSubmitting);
        }}
      >
        {({
          handleSubmit,
          handleChange,
          isSubmitting,
          values,
          errors,
          touched
        }) => (
          <form
            onSubmit={handleSubmit}
            className="flex flex-col bg-white flex-grow justify-between"
          >
            <div className="flex flex-col mt-2">
              <span className="font-roboto font-bold text-socotec-gray-100 opacity-100 tracking-02px text-20px ml-4">
                {intl.messages["ReportPage.message"]}
              </span>
              <textarea
                id="message"
                name="message"
                value={values.message}
                onChange={handleChange}
                placeholder="Saisissez votre message"
                cols={40}
                rows={10}
                className="font-roboto tracking-016px opacity-30 text-16px text-socotec-gray-100 border-none px-4 py-4"
              />
            </div>
            {errors.message && touched.message && (
              <div className="text-red-700 text-center font-normal font-roboto italic text-sm mt-1">
                {errors.message}
              </div>
            )}
            <div className="flex flex-col mx-10 my-4">
              <Button>
                <span className="font-roboto">
                  {intl.messages["ReportPage.send"]}
                </span>
              </Button>
            </div>
          </form>
        )}
      </Formik>
      {contacts && contacts.length > 0 && (
        <ModalRecipients
          isOpen={showModalRecipients}
          onClick={handleShowModalRecipients}
          contacts={contacts}
          recipients={recipients}
          onCheck={handleCheckRecipient}
        />
      )}
    </div>
  );
};

export default injectIntl(Report);
