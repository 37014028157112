import React from "react";
import _ from "lodash"
import classNames from "classnames";

interface Props {
  children: React.ReactNode;
  disabled?: boolean;
  theme?: string;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>;
  className?: string;
  onClick?: () => void;
}

const Button = ({ children, disabled, theme, type, className, onClick }: Props) => {
  const defaultTheme = _.isEmpty(theme)

  const isPrimary = theme == "primary"
  const isSecondary = theme == "secondary"
  const isInfo = theme == "info"

  const btnClass = classNames(
    "w-full font-roboto tracking-048px py-2 px-2 rounded-5px",
    { "opacity-50": disabled, "opacity-100": !disabled },
    { "cursor-not-allowed": disabled },
    { "bg-socotec-blue-100 text-white": isPrimary || defaultTheme },
    { "border border-2 border-socotec-gray-600 text-socotec-gray-100": isSecondary },
    { "border border-2 border-socotec-blue-100 text-socotec-blue-100": isInfo },
    className
  );

  return (
    <button
      onClick={onClick}
      className={btnClass}
      type={type ? type.type : "submit"}
    >
      {children}
    </button>
  );
};

export default Button;
