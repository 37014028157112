import React from "react"
import images from "../../../images"

interface Props {
  onClick?: () => void;
}

const ButtonHome = ({ onClick }: Props) => {
  return (<img
    src={images.home}
    className="absolute self-center"
    alt="Accueil"
    onClick={onClick}
  />)
}

export default ButtonHome