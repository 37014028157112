import React from "react"
import { Worker } from "../../../models/Interfaces/Worker";
import images from "../../../images"

interface Props {
  worker: Worker;
  className?: string;
  photoHandler?: () => void;
  editHandler?: () => void;
  swipe?: boolean;
}

const WorkerDetails = ({ worker, className, photoHandler, editHandler, swipe }: Props) => {
  const pictureOk = worker && worker.btp_card_image;

  return (
    <div>
      <div
        className={className}
      >
        <div className="flex justify-between items-center py-3 px-3 ">
          <div className="flex flex-col justify-center px-2" onClick={photoHandler}>
            {pictureOk && <img src={images.picture} alt="#" />}
            {!pictureOk && <div className="rounded bg-socotec-gray-100 py-3 px-5"><img src={images.plusWhite} className="fill-current text-white" alt="+" /></div>}
          </div>
          <div className={`flex flex-col flex-1 justify-center ml-2`} onClick={editHandler}>
            <span className="font-roboto uppercase text-socotec-gray-200 text-13px tracking-011px opacity-100 mb-1">
              {worker.company ? worker.company.name : ""}
            </span>
            <span
              className=
              "text-left font-roboto tracking-normal text-18px opacity-100 text-socotec-gray-100"
            >
              {worker.firstname} {worker.lastname}
            </span>

            <span className="font-roboto text-socotec-gray-100 text-13px tracking-011px opacity-100">
              {worker.btp_number}
            </span>
          </div>
          <div className="mr-2">
            {!swipe && (<img src={images.arrowRight} alt=">" />)}
            {swipe && (
              <div className="flex flex-col">
                <div className="rounded bg-socotec-gray-500 p-2px mb-1"></div>
                <div className="rounded bg-socotec-gray-500 p-2px mb-1"></div>
                <div className="rounded bg-socotec-gray-500 p-2px"></div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default WorkerDetails