import React from "react"
import images from "../../../images"

interface Props {
  onClick?: () => void;
}

const ButtonBack = ({ onClick }: Props) => {
  return (<img
    src={images.arrowLeft}
    className="absolute self-center"
    alt="<"
    onClick={onClick}
  />)
}

export default ButtonBack