import React from "react"
import _ from "lodash"
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import classNames from "classnames"
import { AppState } from "../../../redux/reducers";
import { Site } from "../../../models/Interfaces/Site"
import MissionStatus from "../../../enums/mission"
import images from "../../../images"

interface Props {
  site: Site;
  className?: string;
  onClickLocation: () => void;
  pathToRedirect?: string
}

const SiteDetails = ({ site, className, onClickLocation }: Props) => {
  const history = useHistory()

  const user = useSelector((state: AppState) => state.authentication.user);

  const contentClassName = classNames(className, "flex justify-between py-3 px-3")

  const currentPendingMission = _.findLast(site.missions, mission => mission.status == MissionStatus.PENDING && mission.author?.id == user.id)

  let path = "/site/" + site.id
  if (currentPendingMission) path = path + '/' + currentPendingMission.id

  return (
    <div className={contentClassName}>
      <div className="flex flex-col justify-center">
        {<img src={images.location} alt="Localisation" style={{ height: "45px" }} onClick={onClickLocation} />}
      </div>
      <div className="flex flex-row flex-1 ml-3" onClick={() => {
        history.push(path);
      }}>
        <div
          className={`flex flex-col flex-1 justify-center`}
        >
          <span className="text-left font-roboto uppercase text-socotec-gray-200 text-13px tracking-054px opacity-100">
            {site.client?.name} - {site.project?.name}
          </span>
          <span
            className="text-left font-roboto tracking-normal text-18px opacity-100 text-socotec-gray-100"
          >
            {site.name}
          </span>
        </div>
        <div className="flex flex-col justify-center" style={{ paddingLeft: "10px", paddingRight: "10px" }}>
          <img src={images.arrowRight} alt=">" />
        </div>
      </div>
    </div>
  )
}

export default SiteDetails