import { defineMessages } from "react-intl";

const messages = defineMessages({
  projectName: {
    id: "SignInPage.project.name",
    defaultMessage: "Présence"
  },
  emailLabel: {
    id: "SignInPage.email.label",
    defaultMessage: "Email"
  },
  emailPlaceholder: {
    id: "SignInPage.email.placeholder",
    defaultMessage: "Email"
  },
  passwordLabel: {
    id: "SignInPage.password.label",
    defaultMessage: "Mot de passe"
  },
  passwordPlaceholder: {
    id: "SignInPage.password.placeholder",
    defaultMessage: "Mot de passe"
  },
  signIn: {
    id: "SignInPage.sign.in",
    defaultMessage: "Sign In"
  },
  emailInvalid: {
    id: "SignInPage.email.invalid",
    defaultMessage: "Le format de l'email est incorrect"
  },
  emailRequired: {
    id: "SignInPage.email.required",
    defaultMessage: "L'email est obligatoire"
  },
  passwordRequired: {
    id: "SignInPage.password.required",
    defaultMessage: "Le mot de passe est obligatoire"
  }
});

export default messages;
