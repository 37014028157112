import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { injectIntl, useIntl } from "react-intl";
import Swipeout from "rc-swipeout";
import classNames from "classnames";
import images from "../../images";
import * as siteActions from "../../redux/Site/actions";
import * as workerActions from "../../redux/Worker/actions";
import { Header, Sticky, ButtonBack } from "../../components/General";
import Button from "../../components/Button";
import InputSearch from "../../components/Input/Search";
import ModalCreateWorker from "../../components/Modals/Worker/New";
import ModalUpdateWorker from "../../components/Modals/Worker/Update";
import { Worker } from "../../models/Interfaces/Worker";
import ModalControl from "../../components/Modals/Control";
import { status as ControlStatus } from "../../enums/control";
import {
  showErrorNotification,
  showSuccessNotification,
  showTreatmentNotification
} from "../../utils/Notification";
import {
  getAllWorkers,
  getCheckedWorkers,
  getApprouvedWorkers,
  getRefusedWorkers,
  getNotControlledWorkers
} from "../../utils/Workers";
import { AppState } from "../../redux/reducers";
import { Mission } from "../../models/Interfaces/Mission";
import WorkerDetails from "../../components/Worker/Details";
import { useThunkDispatch } from "../../redux/hooks";
import OfflineDetector from "../../services/OfflineDetector";

interface Props { }

const Workers = (props: Props) => {
  const [searchWorkers, setSearchWorkers] = useState([] as Worker[]);
  const [selectedWorker, setSelectedWorker] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [forceOpenCamera, setForceOpenCamera] = useState(false)

  const [showModalCreateWorker, setShowModalCreateWorker] = useState(false);
  const [showModalUpdateWorker, setShowModalUpdateWorker] = useState(false);
  const [showModalControl, setShowModalControl] = useState(false);

  const dispatch = useThunkDispatch();
  const history = useHistory();
  const location = useLocation();
  const intl = useIntl();

  const { id, mid } = useParams();

  const search = location.search;

  let query = ControlStatus.NOT_DONE;
  if (search) {
    const tab = search.split("=");
    query = tab[1];
  }

  const { site, workers } = useSelector((state: AppState) => ({
    site: state.site.site,
    workers: state.worker.workers
  }));

  useEffect(() => {
    handleGetSite();
    handleListWorkers();
    return () => { };
  }, []);

  const handleGetSite = () => {
    if (id) {
      dispatch(siteActions.getSiteDispatch({ id: id }))
        .then(res => { })
        .catch(err => {
          showErrorNotification(
            "Une erreur s'est produite lors du chargement du chantier"
          );
        });
    }
  };

  const getFilteredWorkers = () => {
    switch (query) {
      case ControlStatus.VALIDATED:
        return getCheckedWorkers(workers, mid);
      case ControlStatus.APPROUVED:
        return getApprouvedWorkers(workers, mid);
      case ControlStatus.REFUSED:
        return getRefusedWorkers(workers, mid);
      case ControlStatus.NOT_DONE:
        return getNotControlledWorkers(workers, mid);
      default:
        return getAllWorkers(workers, mid);
    }
  };

  const handleFilterWorkers = () => {
    const filteredWorkers = getFilteredWorkers();
    setSearchWorkers(filteredWorkers);
  };

  const handleListWorkers = () => {
    if (id) {
      const site = {
        id: id
      };

      if (OfflineDetector.appIsOnline()) {
        dispatch(workerActions.getWorkersDispatch({ site: site }))
          .then(() => { })
          .catch((err: any) => {
            showErrorNotification(
              "Une erreur s'est produite lors du chargement des travailleurs"
            );
          });
      } else {
        dispatch(workerActions.getWorkersDispatch({ site: site }));
      }
    }
  };

  useEffect(() => {
    if (searchValue.length > 0) {
      const workersAll = getFilteredWorkers();

      const values = workersAll.filter(worker => {
        try {
          const firstname = worker.firstname.toLowerCase();
          const lastname = worker.lastname.toLowerCase();
          const companyName = worker.company
            ? worker.company.name.toLowerCase()
            : "";
          const btpNumber = worker.btp_number
            ? worker.btp_number.toLowerCase()
            : "";

          return (
            firstname.search(searchValue) != -1 ||
            lastname.search(searchValue) != -1 ||
            companyName.search(searchValue) != -1 ||
            btpNumber.search(searchValue) != -1
          );
        } catch (e) {
          return false
        }
      });

      setSearchWorkers(values);
    } else {
      handleFilterWorkers();
    }
  }, [workers, searchValue]);

  const handleShowModalCreateWorker = () => {
    setShowModalCreateWorker(!showModalCreateWorker);
  };

  const handleShowModalUpdateWorker = () => {
    setShowModalUpdateWorker(!showModalUpdateWorker);
  };

  const handleShowModalControl = () => {
    setShowModalControl(!showModalControl);
  };

  const handleDeleteControl = (worker: Worker) => {
    let control;
    if (worker && !_.isEmpty(worker.controles)) {
      control = worker.controles?.find(control => control.mission.id == mid);
    }

    if (control && id) {
      const payload = {
        id: control.id,
        worker: worker,
        site: { id: id }
      };

      dispatch(workerActions.deleteControlDispatch(payload, control))
        .then(res => {
          showSuccessNotification("Le contrôle a été retiré du travailleur");
        })
        .catch(err => {
          showErrorNotification(
            "Le contrôle n'a pas pu être retiré du travailleur"
          );
        });
    }
  };

  const handleUpdateControl = (worker: Worker, status: string) => {
    let control;
    if (worker && !_.isEmpty(worker.controles)) {
      control = worker.controles?.find(e => e.mission.id == mid);
    }

    if (control && id) {
      const payload = {
        id: control.id,
        btp_number: worker.btp_number,
        comment: control.comment,
        status: status,
        site: {
          id: id
        },
        mission: {
          id: mid
        } as Mission,
        worker: worker
      };

      dispatch(workerActions.updateControlDispatch(payload, control, false))
        .then(res => {
          showSuccessNotification("Le contrôle a été mis à jour avec succès");
        })
        .catch(err => {
          showErrorNotification("Le contrôle n'a pas été mis à jour");
        });
    } else if (id && mid) {
      // no control found for this worker and this mission, we will create him a control
      const payload = {
        btp_number: "",
        comment: "",
        status: status,
        worker: worker,
        mission: {
          id: mid
        },
        site: {
          id: id
        }
      };

      dispatch(workerActions.createControlDispatch(payload, false))
        .then(res => {
          showTreatmentNotification("TRAVAILLEUR CONTROLÉ");
        })
        .catch(err => {
          showErrorNotification("Le contrôle n'a pas été crée");
        });
    }
  };

  const refusedFilter = ControlStatus.REFUSED === query;
  const notDoneFilter = ControlStatus.NOT_DONE === query;
  const validatedFilter = ControlStatus.VALIDATED === query;

  // @todo: refactor path
  const IconHeader = (
    <ButtonBack
      onClick={() => {
        history.push({
          pathname: `/site/${id}${mid ? "/" + mid : ""}`,
          state: { transition: "slideRight" }
        });
      }}
    ></ButtonBack>
  );

  const pageTitle = mid ? intl.messages["WorkersPage." + query] : site?.name;

  let headerClassName = "bg-white";
  switch (query) {
    case ControlStatus.VALIDATED:
      headerClassName = classNames(
        headerClassName,
        "border-t-6px border-solid border-socotec-green-200"
      );
      break;
    case ControlStatus.APPROUVED:
      headerClassName = classNames(
        headerClassName,
        "border-t-6px border-solid border-socotec-orange-100"
      );
      break;
    case ControlStatus.REFUSED:
      headerClassName = classNames(
        headerClassName,
        "border-t-6px border-solid border-socotec-red-100"
      );
      break;
  }

  return (
    <div className="flex flex-col w-full min-h-screen bg-socotec-white-100">
      <Sticky className={headerClassName}>
        <Header
          title={pageTitle}
          icon={IconHeader}
          shadow
        >
          <div className="flex flex-row bg-white pt-4 react-autosuggest__container-lite">
            <div className="w-full">
              <InputSearch setSearch={setSearchValue} placeholder="Rechercher" small={!mid} />
            </div>
            {!mid && (
              <>
                <div style={{ width: "20px" }}></div>
                <Button
                  theme="secondary"
                  className="flex flex-row justify-center items-center text-18px"
                  onClick={() => {
                    handleShowModalCreateWorker();
                  }}
                >
                  {intl.messages["WorkersPage.add"]}{" "}
                  <img src={images.plus} alt="+" className="ml-2" />
                </Button>
              </>
            )}
          </div>
        </Header>
      </Sticky>
      <div className="z-1">
        {searchWorkers &&
          searchWorkers?.map((worker, index) => {
            let className = "";
            if (refusedFilter || notDoneFilter) {
              className =
                index % 2 === 0
                  ? "bg-socotec-white-100 cursor-pointer"
                  : "bg-white cursor-pointer";
            } else if (validatedFilter) {
              className =
                index % 2 === 0
                  ? "bg-socotec-white-100 cursor-pointer"
                  : "bg-white cursor-pointer";
            }

            const valideAction = {
              text: (
                <div
                  className={
                    "flex flex-col justify-center bg-socotec-green-100 cursor-pointer "
                  }
                  style={{ width: "100px" }}
                  onClick={() => {
                    handleUpdateControl(worker, ControlStatus.VALIDATED);
                  }}
                >
                  <img src={images.valider} alt="" />
                  <span className="text-white text-center font-roboto text-11px tracking-normal opacity-100 pt-2">
                    Valider
                  </span>
                </div>
              ),
              onPress: () => { },
              style: {
                backgroundColor: "#0FD17F",
                color: "white",
                width: 100
              }
            };

            const refuseAction = {
              text: (
                <div
                  className="flex flex-col justify-center bg-socotec-red-100 cursor-pointer"
                  style={{ width: "100px" }}
                  onClick={() => {
                    handleUpdateControl(worker, ControlStatus.REFUSED);
                  }}
                >
                  <img src={images.fermer} alt="" />
                  <span className="text-white text-center font-roboto text-11px tracking-normal opacity-100 pt-2">
                    Refuser
                  </span>
                </div>
              ),
              onPress: () => { },
              style: {
                backgroundColor: "#BE0000",
                color: "white",
                width: 100
              }
            };

            const cancelAction = {
              text: (
                <div
                  className="flex flex-col justify-center bg-socotec-gray-100 cursor-pointer"
                  style={{ width: "100px" }}
                  onClick={() => { }}
                >
                  <img src={images.fermer} alt="" />
                  <span className="text-white text-center font-roboto text-11px tracking-normal opacity-100 pt-2">
                    Annuler
                  </span>
                </div>
              ),
              onPress: () => { },
              style: {
                backgroundColor: "#435877",
                color: "white",
                width: 100
              }
            };

            const resetAction = {
              text: (
                <div
                  className="flex flex-col justify-center bg-socotec-gray-100 cursor-pointer"
                  style={{ width: "100px" }}
                  onClick={() => {
                    handleDeleteControl(worker);
                  }}
                >
                  <img src={images.interrogation} alt="" />
                  <span className="text-white text-center font-roboto text-11px tracking-normal opacity-100 pt-2">
                    Non contrôlé
                  </span>
                </div>
              ),
              onPress: () => { },
              style: {
                backgroundColor: "#435877",
                color: "white",
                width: 100
              }
            };

            let menuActions = [cancelAction];
            if (validatedFilter) {
              menuActions = [resetAction, refuseAction];
            }

            if (refusedFilter) {
              menuActions = [resetAction, valideAction];
            }

            if (notDoneFilter) {
              menuActions = [cancelAction, valideAction, refuseAction];
            }

            if (!worker) {
              return <></>;
            }

            if (mid) {
              return (
                <Swipeout autoClose key={index} right={menuActions}>
                  <WorkerDetails
                    worker={worker}
                    className={className}
                    photoHandler={() => {
                      setSelectedWorker(worker);
                      setForceOpenCamera(true)
                      handleShowModalUpdateWorker();
                    }}
                    editHandler={() => {
                      setSelectedWorker(worker);
                      setForceOpenCamera(false)
                      handleShowModalControl();
                    }}
                    swipe
                  />
                </Swipeout>
              );
            } else {
              return (
                <WorkerDetails
                  key={index}
                  worker={worker}
                  className={className}
                  photoHandler={() => {
                    setSelectedWorker(worker);
                    setForceOpenCamera(true)
                    handleShowModalUpdateWorker();
                  }}
                  editHandler={() => {
                    setSelectedWorker(worker);
                    setForceOpenCamera(false)
                    handleShowModalUpdateWorker();
                  }}
                />
              );
            }
          })}
      </div>

      {_.isEmpty(searchWorkers) && (
        <div className="flex flex-col justify-center flex-grow">
          <div className="flex flex-col -mt-10">
            <span className="italic text-center font-roboto text-socotec-gray-100 font-bold">
              {intl.messages["WorkersPage.noResults"]}
            </span>
          </div>
        </div>
      )}

      {id && site && (
        <ModalCreateWorker
          isOpen={showModalCreateWorker}
          onClick={handleShowModalCreateWorker}
          site={{ id: id, companies: site.companies }}
        />
      )}

      {id && site && selectedWorker && (
        <ModalUpdateWorker
          isOpen={showModalUpdateWorker}
          onClick={handleShowModalUpdateWorker}
          site={{ id: id, companies: site.companies }}
          worker={selectedWorker}
          forceOpenCamera={forceOpenCamera}
          setForceOpenCamera={setForceOpenCamera}
        />
      )}

      {id && mid && selectedWorker && (
        <ModalControl
          isOpen={showModalControl}
          onClick={handleShowModalControl}
          onEdit={handleShowModalUpdateWorker}
          site={{ id: id }}
          mission={{ id: mid }}
          worker={selectedWorker}
          query={query}
        />
      )}
    </div>
  );
};

export default injectIntl(Workers);
