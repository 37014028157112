import { defineMessages } from "react-intl";

const messages = defineMessages({
  btpNumber: {
    id: "ModalControl.btpNumber",
    defaultMessage: "N° carte BTP"
  },
  comment: {
    id: "ModalControl.comment",
    defaultMessage: "Commentaires liés au contrôle"
  },
  save: {
    id: "ModalControl.save",
    defaultMessage: "Enregister"
  },
  btpNumberRequired: {
    id: "ModalControl.btp.required",
    defaultMessage: "Le N° de carte BTP est obligatoire"
  },
  authorized: {
    id: "ModalControl.authorized",
    defaultMessage: "Autorisée"
  },
  unauthorized: {
    id: "ModalControl.unauthorized",
    defaultMessage: "Non autorisée"
  },
  pending: {
    id: "ModalControl.pending",
    defaultMessage: "En attente"
  },
});

export default messages;
