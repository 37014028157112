import React from "react"
import classNames from "classnames"

interface Props {
  children: React.ReactNode;
  className?: string;
}

const ModalContent = ({ children, className }: Props) => {
  const contentClassName = classNames(className, "mt-4")
  return (
    <div className={contentClassName} style={{ padding: "20px" }}>{children}</div>
  )
}

export default ModalContent