import React, { useState, useEffect } from "react"
import classNames from "classnames"

interface Props {
  className?: string
  style?: Object
  children?: React.ReactNode
}

const Sticky = ({ className, style, children }: Props) => {
  const [isScrolling, setIsScrolling] = useState(false)
  const [timer, setTimer] = useState<React.SetStateAction<any>>()

  useEffect(() => {
    const scrollStop = () => {
      setIsScrolling(false)
    }

    const handleScrolling = () => {
      clearTimeout(timer)

      if (!isScrolling) setIsScrolling(true)

      setTimer(setTimeout(scrollStop, 100))
    }

    window.addEventListener("scroll", handleScrolling)

    return () => {
      window.removeEventListener("scroll", handleScrolling)
    }
  }, [timer, isScrolling])

  const stickyClassName = classNames(className, "sticky z-2 animate", {
    "slide-up": isScrolling
  })

  return (<div className={stickyClassName} style={style}>{children}</div>)
}

export default Sticky