import React from "react";

interface Props {
  children: React.ReactNode;
  disabled?: boolean;
  badge?: React.ReactNode;
  onClick?: () => void;
}

const Card = ({ children, disabled, badge, onClick }: Props) => {
  return (
    <div className="relative h-full flex-1 flex flex-col justify-center items-center bg-white rounded-lg shadow-lg" style={{ padding: "20px 40px", marginBottom: "30px" }} onClick={onClick}>
      {children}
      {!disabled && badge}
      {disabled && (
        <div className="absolute flex-1 top-0 left-0 w-full h-full rounded-lg">
          <div className="absolute top-0 left-0 w-full h-full pending-1 opacity-80"></div>
          <div className="relative" style={{ margin: "20px 40px" }}>{badge}</div>
        </div>
      )
      }
    </div >
  )
}

export default Card