import React from "react";
import ReactModal from "react-modal";
import { useIntl } from "react-intl";
import { Site } from "../../../models/Interfaces/Site";
import ModalHeader from "../ModalHeader"
import ModalClose from "../ModalClose"
import ModalContent from "../ModalContent"
interface Props {
  site: Site;
  isOpen: boolean;
  onClick: () => void;
  closeTimeoutMS: number
}

const customStyles = {
  content: {
    top: 0,
    padding: 0,
    background: "#F5F7FC"
  },
  overlay: {
    zIndex: 99
  }
};

const ModalInfos = (props: Props) => {
  const intl = useIntl();

  const closeModal = <ModalClose onClick={props.onClick} />

  return (
    <ReactModal
      isOpen={props.isOpen}
      contentLabel="modal-infos"
      appElement={document.getElementById("root")}
      style={customStyles}
      closeTimeoutMS={props.closeTimeoutMS}
    >
      <ModalHeader sticky title={intl.messages["ModalInfos.additional.informations"]} closeButton={closeModal}></ModalHeader>

      <ModalContent>
        <div className="flex flex-row" style={{ paddingBottom: "80px" }}>
          <span className="text-18px tracking-normal text-socotec-gray-100 font-roboto">
            {props.site.additional_informations}
          </span>
        </div>
      </ModalContent>
    </ReactModal>
  );
};

export default ModalInfos;
