import React from "react";
import classNames from "classnames";
import "./styles.css";
import images from "../../../images";

interface Props {
  id: string;
  name: string;
  value: string;
  type: string;
  placeholder: string;
  label: string;
  onChange?: (e: any) => void;
  onFocus?: () => void
  onBlur?: () => void
  className?: string;
  onClick?: () => void;
  icon?: boolean;
  disabled?: boolean;
}

const InputText = (props: Props) => {
  const inputClass = classNames(props.className, "relative");

  return (
    <div className={inputClass}>
      <input
        id={props.id}
        name={props.name}
        type={props.type}
        value={props.value}
        placeholder={props.placeholder}
        className="relative font-roboto floating-label-field floating-label-field--s3"
        autoComplete="off"
        onChange={props.onChange}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        disabled={props.disabled}
      />
      <label className="floating-label">{props.label}</label>
      {props.icon && (
        <div
          className="flex flex-row absolute items-center px-4 z-0"
          style={{ height: "100%", right: 0, top: 0 }}
        >
          <img src={images.show} alt="#" onClick={props.onClick} />
        </div>
      )}
    </div>
  );
};

export default InputText;
