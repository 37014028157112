import React from "react";
import ReactModal from "react-modal";
import { useIntl } from "react-intl";
import classNames from "classnames";
import images from "../../../images";
import { Contact } from "../../../models/Interfaces/Contact";
import Button from "../../Button";

interface Props {
  isOpen: boolean;
  onClick: () => void;
  contacts: Contact[];
  recipients: Contact[];
  onCheck: (e) => void;
}

const customStyles = {
  content: {
    top: "20%"
  }
};

const ModalRecipients = (props: Props) => {
  const intl = useIntl();

  return (
    <ReactModal
      isOpen={props.isOpen}
      contentLabel="modal-recipients"
      appElement={document.getElementById("root")}
      style={customStyles}
    >
      <div className="flex flex-row justify-between mb-4">
        <img src={images.close} alt="X" onClick={props.onClick} />
        <span className="font-roboto text-socotec-blue-100 text-16px opacity-100 tracking-016px">
          {intl.messages["ModalRecipients.select.all"]}
        </span>
      </div>

      {props.contacts &&
        props.contacts.map((contact, index) => {
          const blockClass = classNames("flex flex-col mt-2");

          const recipient = props.recipients.find(e => e.id == contact.id);
          const checked = recipient !== null && recipient !== undefined;

          return (
            <div className={blockClass} key={index}>
              <div className="flex flex-row items-center justify-between">
                <span className="font-roboto text-socotec-gray-100 opacity-100 text-16px tracking-normal">
                  {contact.firstname} {contact.lastname}
                </span>
                <input
                  id={contact.id}
                  type="checkbox"
                  checked={checked}
                  onChange={props.onCheck}
                />
                <label className="checkbox-label" htmlFor={contact.id}></label>
              </div>
              <div className="flex flex-row pb-4">
                <div className="flex flex-col">
                  <span className="font-roboto uppercase text-socotec-gray-100 opacity-100 text-11px tracking-011px">
                    {contact.company_name}
                  </span>
                </div>
              </div>
            </div>
          );
        })}

      <div className="flex flex-col mt-6 mx-6">
        <Button>
          <span className="font-roboto">
            {intl.messages["ModalRecipients.add"]}
          </span>
        </Button>
      </div>
    </ReactModal>
  );
};

export default ModalRecipients;
