import store, { promiseListener } from "../../redux/store";

import {
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAILURE,
  REFRESH_TOKEN,
  SIGN_OUT
} from "../../redux/Authentication/actionTypes";
import { refreshToken } from "../../redux/Authentication/actions";
import { showErrorNotification } from "../Notification";
import { history } from "../../App";

export const _refreshAccessToken = async action => {


  const generatedAsyncFunction = promiseListener.createAsyncFunction({
    //  start: TOKEN_REFRESH, // the type of action that will resolve the promise
    start: REFRESH_TOKEN, // the type of action that will resolve the promise
    resolve: REFRESH_TOKEN_SUCCESS, // the type of action that will resolve the promise
    reject: REFRESH_TOKEN_FAILURE // the type of action that will reject the promise
  });


  store.dispatch(refreshToken() as any);

  generatedAsyncFunction.asyncFunction().then(
    payload => {

      action.meta.offline.effect.headers.Authorization = `Bearer ${payload.token}`;

      return false;
    },
    rejectPayload => {
      console.log({
        "PAYLOAD AFTER REJECT ===> ": rejectPayload
      });

      showErrorNotification("Votre session a expiré");
      store.dispatch({ type: SIGN_OUT });
      history.push("/login");


      return true;
    }
  );

  // when done, to prevent memory leaks
  //  generatedAsyncFunction.unsubscribe();
};
