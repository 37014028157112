import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useHistory, useParams } from "react-router-dom";
import { injectIntl, useIntl } from "react-intl";
import images from "../../images";
import * as siteActions from "../../redux/Site/actions";
import { isPending, isReportSent } from "../../enums/mission";
import { isClosed } from "../../enums/site";
import { showErrorNotification } from "../../utils/Notification";
import { AppState } from "../../redux/reducers";
import { useThunkDispatch } from "../../redux/hooks";
import OfflineDetector from "../../services/OfflineDetector";

interface Props { }

const Missions = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  const dispatch = useThunkDispatch();
  const history = useHistory();
  const intl = useIntl();

  // pull out state from our store
  const { site, error } = useSelector((state: AppState) => ({
    site: state.site.site,
    error: state.site.error
  }));

  useEffect(() => {
    handleGetSite();
  }, []);

  const handleGetSite = () => {
    if (id) {
      if (OfflineDetector.appIsOnline()) {
        setLoading(true);
        dispatch(siteActions.getSiteDispatch({ id: id }))
          .then(res => {
            setLoading(false);
          })
          .catch(err => {
            setLoading(false);
            showErrorNotification(
              "Une erreur s'est produite lors du chargement des visites"
            );
          });
      } else {
        dispatch(siteActions.getSiteDispatch({ id: id }));
      }
    }
  };

  return (
    <div className="flex flex-col w-full min-h-screen bg-socotec-white-100">
      <div className="flex  bg-white flex-row py-4 px-4 py-4 sticky">
        <div className="flex-1 flex flex-col ">
          <span className="text-center text-socotec-gray-100 font-roboto font-bold text-20px tracking-normal opacity-100">
            {intl.messages["MissionsPage.title"]}
          </span>
        </div>

        <img
          src={images.retour}
          className="absolute self-center pl-4"
          alt="Retour"
          onClick={() => {
            history.push(`/home`);
          }}
        />
      </div>

      <ul>
        {site?.missions &&
          site?.missions.map((mission, index) => {
            let className =
              index % 2 === 0
                ? "bg-socotec-white-100 cursor-pointer"
                : "bg-white cursor-pointer";

            const pending = isPending(mission);
            const closed = isClosed(mission);
            const reportSent = isReportSent(mission);

            let textColor = "text-socotec-gray-100";

            if (pending) {
              className = index % 2 === 0 ? "pending-0" : "bg-white";
              textColor = "text-socotec-gray-200";
            }

            let bgColor = "bg-socotec-green-100";
            if (closed) {
              bgColor = "bg-socotec-blue-100";
            }
            if (reportSent) {
              bgColor = "bg-socotec-gray-300";
            }

            return (
              <li
                key={index}
                className={className}
                onClick={() => {
                  history.push(`/site/${site.id}/${mission.id}`);
                }}
              >
                <div className="flex justify-between py-3 px-3">
                  <div
                    className={`flex flex-col justify-center w-7/12 ml-2 sm:ml-0`}
                  >
                    <span className="text-left font-roboto uppercase text-socotec-gray-200 text-9px tracking-054px opacity-100">
                      {site.client?.name} - {site.project?.name}
                    </span>
                    <span
                      className={
                        `text-left font-roboto tracking-normal text-15px opacity-100 ` +
                        textColor
                      }
                    >
                      {site.name}
                    </span>
                    <span
                      className={
                        `text-left mt-2 italic font-roboto tracking-normal text-11px opacity-100 ` +
                        textColor
                      }
                    >
                      Visite initiéé par{" "}
                      <span className="font-bold">
                        {mission.author?.firstname}
                      </span>{" "}
                      <span className="font-bold uppercase">
                        {mission.author?.lastname}
                      </span>
                    </span>
                  </div>
                  <div className="flex flex-col w-3/12 sm:w-1/12 justify-center mr-2 sm:mr-0">
                    <span
                      className={
                        `inline-block font-roboto text-xs text-center rounded-20px text-white py-1 ` +
                        bgColor
                      }
                    >
                      {intl.messages["MissionsPage." + mission.status]}
                    </span>
                  </div>
                  <div className="flex flex-col justify-center">
                    <img src={images.arrowRight} alt=">" />
                  </div>
                </div>
              </li>
            );
          })}
      </ul>

      {_.isEmpty(site?.missions) && !loading && !error && (
        <div className="flex flex-col justify-center flex-grow">
          <div className="flex flex-col -mt-10">
            <span className="italic text-center font-roboto text-socotec-gray-100 font-bold">
              {intl.messages["MissionsPage.noResults"]}
            </span>
          </div>
        </div>
      )}

      {_.isEmpty(site?.missions) && !loading && error && (
        <div className="flex flex-col justify-center flex-grow">
          <div className="flex flex-col -mt-10">
            <span className="italic text-center font-roboto text-socotec-gray-100 font-bold">
              Une erreur s'est produite, veuillez actualiser la page
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default injectIntl(Missions);
