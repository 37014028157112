import React from "react";
import "./styles.css";

interface Props {
  dataUri: string;
  isFullscreen: boolean;
}

export const ImagePreview = (props: Props) => {
  let classNameFullscreen = props.isFullscreen
    ? "image-preview-fullscreen"
    : "";
  return (
    <div className={"image-preview " + classNameFullscreen}>
      <img src={props.dataUri} alt="#" />
    </div>
  );
};

export default ImagePreview;
