import React from "react";
import ReactModal from "react-modal";
import { useIntl } from "react-intl";
import { Site } from "../../../models/Interfaces/Site";
import { Contacts } from "../../../models/Interfaces/Contact";
import ModalHeader from "../ModalHeader"
import ModalClose from "../ModalClose"
import ModalContent from "../ModalContent"
interface Props {
  site: Site;
  isOpen: boolean;
  onClick: () => void;
  closeTimeoutMS: number
}

const customStyles = {
  content: {
    top: 0,
    padding: 0,
    background: "#F5F7FC"
  },
  overlay: {
    zIndex: 99
  }
};

const ModalAgence = (props: Props) => {
  const intl = useIntl();

  const principles = props.site.contacts?.filter(e => e.principle === true);
  const others = props.site.contacts?.filter(e => e.principle !== true);

  let contacts = [] as Contacts[];
  if (principles) {
    contacts = contacts.concat(principles);
  }
  if (others) {
    contacts = contacts.concat(others);
  }

  const closeModal = <ModalClose onClick={props.onClick} />

  return (
    <ReactModal
      isOpen={props.isOpen}
      contentLabel="modal-agence"
      appElement={document.getElementById("root")}
      style={customStyles}
      closeTimeoutMS={props.closeTimeoutMS}
    >
      <ModalHeader sticky title={intl.messages["ModalAgence.title"]} closeButton={closeModal}></ModalHeader>

      <ModalContent>
        <div className="flex flex-col mt-8">
          <div className="flex flex-row">
            <span className="font-roboto uppercase text-socotec-gray-200 opacity-100 text-13px tracking-11px">
              {intl.messages["ModalAgence.agence"]}
            </span>
          </div>
          <div className="flex flex-row border-b border-b-2 border-socotec-gray-300 pb-4">
            <span className="font-roboto text-socotec-gray-100 opacity-100 text-18px tracking-normal">
              {props.site.contract?.name}
            </span>
          </div>
        </div>

        <div className="flex flex-col mt-6" style={{ paddingBottom: "60px" }}>
          <div className="flex flex-row">
            <span className="font-roboto uppercase text-socotec-gray-200 opacity-100 text-13px tracking-11px">
              {intl.messages["ModalAgence.contacts"]}
            </span>
          </div>
          <div className="flex flex-col pb-4">
            {contacts &&
              contacts.map((contact, index) => {
                return (
                  <span
                    className="font-roboto text-socotec-gray-100 opacity-100 text-18px tracking-normal"
                    key={index}
                  >
                    {contact.contact.firstname} {contact.contact.lastname} - {contact.contact.phone}
                  </span>
                );
              })}
          </div>
        </div>
      </ModalContent>
    </ReactModal>
  );
};

export default ModalAgence;
