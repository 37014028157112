import React from "react"
import images from "../../images";

interface Props {
  onClick?: () => void;
  icon?: string;
}

const ModalClose = ({ onClick, icon }: Props) => {
  let src = images.close
  if (icon) src = icon

  return (
    <img src={src} alt="X" className="absolute self-center" onClick={onClick} />
  )
}

export default ModalClose