import React from "react";
import ReactModal from "react-modal";
import { useIntl } from "react-intl";
import { Site } from "../../../models/Interfaces/Site";
import ModalHeader from "../ModalHeader"
import ModalClose from "../ModalClose"
import ModalContent from "../ModalContent"
interface Props {
  site: Site;
  isOpen: boolean;
  onClick: () => void;
  closeTimeoutMS: number
}

const customStyles = {
  content: {
    top: 0,
    padding: 0,
    background: "#F5F7FC"
  },
  overlay: {
    zIndex: 99
  }
};

const ModalLocation = ({ isOpen, onClick, site, closeTimeoutMS }: Props) => {
  const intl = useIntl();

  const closeModal = <ModalClose onClick={onClick} />

  return (
    <ReactModal
      isOpen={isOpen}
      contentLabel="modal-location"
      appElement={document.getElementById("root")}
      style={customStyles}
      closeTimeoutMS={closeTimeoutMS}
    >
      <ModalHeader sticky title={intl.messages["ModalLocation.title"]} closeButton={closeModal}></ModalHeader>

      <ModalContent>
        <div style={{ paddingBottom: "50px" }}>
          <div className="flex flex-col">
            <div className="flex flex-row">
              <span className="font-roboto uppercase text-socotec-gray-200 opacity-100 text-13px tracking-11px">
                {intl.messages["ModalLocation.title.address"]}
              </span>
            </div>
            <div className="flex flex-row border-b border-b-2 border-socotec-gray-300 pb-4">
              {site.address && (
                <span className="font-roboto text-socotec-gray-100 opacity-100 text-18px tracking-normal underline">
                  {site.address?.street}
                  <br />
                  {site.address?.zipcode} {site.address?.city}
                </span>
              )}
              {!site.address && (
                <span className="italic text-socotec-gray-200 text-13px">Aucune adresse</span>
              )}
            </div>
          </div>

          <div className="flex flex-col mt-6">
            <div className="flex flex-row">
              <span className="font-roboto uppercase text-socotec-gray-200 opacity-100 text-13px tracking-11px">
                {intl.messages["ModalLocation.title.comment"]}
              </span>
            </div>
            <div className="flex flex-row pb-4">
              {site.address && (
                <span className="font-roboto text-socotec-gray-100 opacity-100 text-18px tracking-normal">
                  {site.address?.additional_informations}
                  <br />
                </span>
              )}
            </div>
          </div>
        </div>
      </ModalContent>
    </ReactModal>
  );
};

export default ModalLocation;
