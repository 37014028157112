import React from "react"
import ReactModal from "react-modal";
import { useIntl } from "react-intl";
import { MessageFormatElement } from 'intl-messageformat-parser';
import Button from "../../Button"
import ModalContent from "../../Modals/ModalContent"

interface Props {
  isOpen: boolean;
  textTitle?: string | MessageFormatElement[]
  onValid: () => void;
  onCancel: () => void;
  textValid: string | MessageFormatElement[]
  textCancel: string | MessageFormatElement[]
}

const customStyles = {
  content: {
    top: "60%",
    padding: 0,
  },
  overlay: {
    zIndex: 99999
  }
};

const ModalConfirm = ({ isOpen, onValid, onCancel, textTitle, textValid, textCancel }: Props) => {
  const intl = useIntl()

  return (<ReactModal
    isOpen={isOpen}
    contentLabel="modal-confirm"
    appElement={document.getElementById("root")}
    style={customStyles}
  >
    <ModalContent className="h-full flex flex-col justify-around">
      {textTitle && (
        <div className="p-4 text-socotec-gray-100 font-bold text-20px text-center">{textTitle}</div>
      )}
      <div>
        <Button onClick={onValid} className="mb-4 py-3">{textValid}</Button>
        <Button onClick={onCancel} className="py-3" theme="secondary">{textCancel}</Button>
      </div>
    </ModalContent>
  </ReactModal>)
}

export default ModalConfirm