import React from "react";
import ReactModal from "react-modal";
import classNames from "classnames";
import { useIntl } from "react-intl";
import images from "../../../images";
import { Site } from "../../../models/Interfaces/Site";
import { Contacts } from "../../../models/Interfaces/Contact";
import ModalHeader from "../ModalHeader"
import ModalClose from "../ModalClose"
import { getContactRole } from "../../../utils/Contact"

interface Props {
  site: Site;
  isOpen: boolean;
  onClick: () => void;
  closeTimeoutMS: number
}

const customStyles = {
  content: {
    top: 0,
    padding: 0,
    background: "#F5F7FC"
  },
  overlay: {
    zIndex: 99
  }
};

const ModalContacts = (props: Props) => {
  const intl = useIntl();

  const principles = props.site.contacts?.filter(e => e.principle === true);
  const others = props.site.contacts?.filter(e => e.principle !== true);

  let contacts = [] as Contacts[];
  if (principles) {
    contacts = contacts.concat(principles);
  }
  if (others) {
    contacts = contacts.concat(others);
  }

  const closeModal = <ModalClose onClick={props.onClick} />

  return (
    <ReactModal
      isOpen={props.isOpen}
      contentLabel="modal-location"
      appElement={document.getElementById("root")}
      style={customStyles}
      closeTimeoutMS={props.closeTimeoutMS}
    >
      <ModalHeader sticky title={intl.messages["ModalContacts.title"]} closeButton={closeModal}></ModalHeader>

      <div className="pt-2" style={{ paddingBottom: "80px" }}>
        {contacts &&
          contacts.map((contact, index) => {
            const lineClassName = classNames(
              "flex flex-col pt-4 px-4 relative",
              { "bg-white": index % 2 !== 0 }
            )

            const contactRole = contact.contact?.role ? getContactRole(contact.contact.role) : ""

            return (
              <div className={lineClassName} key={index}>
                {contact.principle && (
                  <div>
                    <span className="inline-flex flex-row items-center bg-socotec-blue-100 rounded-20px px-2">
                      <img src={images.stars} alt="*" />
                      <span className="font-roboto uppercase text-12px text-center text-white ml-2">
                        {intl.messages["ModalContacts.principle"]}
                      </span>
                    </span>
                  </div>
                )}
                <div className="flex flex-row">
                  <span className="uppercase text-socotec-gray-900 text-13px">
                    {contact.contact.company_name}
                  </span>
                </div>
                <div className="flex flex-row border-b border-b-2 border-socotec-gray-300 pb-4">
                  <div className="flex flex-col">
                    <span className="text-socotec-gray-100 text-18px tracking-21px mb-4">
                      {contact.contact.firstname} {contact.contact.lastname} {contactRole && (<span className="bg-socotec-gray-700 rounded-20px text-12px px-2 py-1">{contactRole}</span>)}
                    </span>
                    <span className="text-socotec-gray-600 text-11px tracking-013px italic">
                      {contact.contact.phone}
                      <br />
                      {contact.contact.email}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </ReactModal>
  );
};

export default ModalContacts;
