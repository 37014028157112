export default {
  "SignInPage.project.name": "Présence",
  "SignInPage.email.label": "Email",
  "SignInPage.email.placeholder": "Email",
  "SignInPage.password.label": "Password",
  "SignInPage.password.placeholder": "Password",
  "SignInPage.sign.in": "Sign In",
  "SignInPage.email.invalid": "Email is invalid",
  "SignInPage.email.required": "Email is required",
  "SignInPage.password.required": "Password is required",

  "DashboardPage.button.deconnexion": "Déconnexion",
  "DashboardPage.button.compte": "Mon compte",
  "DashboardPage.title.mesVisites": "Toutes mes visites",
  "DashboardPage.chantier": "chantier",
  "DashboardPage.chantiers": "chantiers",
  "DashboardPage.title.visiteToClose": "Visites à terminer",

  "HomePage.pending": "En cours",
  "HomePage.noResults": "Aucun chantier trouvé",

  "MissionsPage.title": "Liste des visites",
  "MissionsPage.PENDING": "En cours",
  "MissionsPage.CLOSED": "Terminé",
  "MissionsPage.REPORT_SENT": "Envoyé",
  "MissionsPage.noResults": "Aucune mission trouvée pour ce chantier",

  "SitePage.title": "Visite du chantier",
  "SitePage.ref": "REF",
  "SitePage.workers.total": "Nombre de travailleurs enregistrés : ",
  "SitePage.location": "Localisation",
  "SitePage.infos": "Infos",
  "SitePage.contacts": "Contacts",
  "SitePage.agence": "Agence",
  "SitePage.start": "Démarrer",
  "SitePage.new": "Nouveau",
  "SitePage.workers": "Travailleurs",
  "SitePage.terminate": "Terminer ma mission",
  "SitePage.card.completeTitle": "Compléter",
  "SitePage.card.completeText": "les fiches travailleurs",
  "SitePage.card.startTitle": "Démarrer",
  "SitePage.card.startText": "la visite",
  "SitePage.card.resumeTitle": "Continuer",

  "Badge.PENDING": "En cours",

  "ModalCreateWorker.title": "Nouveau travailleur",
  "ModalCreateWorker.firstname": "Prénom",
  "ModalCreateWorker.lastname": "Nom",
  "ModalCreateWorker.company": "Entreprise",
  "ModalCreateWorker.btpNumber": "N° carte BTP",
  "ModalCreateWorker.takePicture": "Prendre en photo la carte BTP",
  "ModalCreateWorker.firstname.required": "Le prénom est obligatoire",
  "ModalCreateWorker.lastname.required": "Le nom est obligatoire",
  "ModalCreateWorker.company.required": "L'entreprise est obligatoire",
  "ModalCreateWorker.btp.required": "Le N° de carte BTP est obligatoire",
  "ModalCreateWorker.check": "Créer",

  "ModalUpdateWorker.title": "Modifier travailleur",
  "ModalUpdateWorker.firstname": "Prénom",
  "ModalUpdateWorker.lastname": "Nom",
  "ModalUpdateWorker.company": "Entreprise",
  "ModalUpdateWorker.btpNumber": "N° carte BTP",
  "ModalUpdateWorker.firstname.required": "Le prénom est obligatoire",
  "ModalUpdateWorker.lastname.required": "Le nom est obligatoire",
  "ModalUpdateWorker.company.required": "L'entreprise est obligatoire",
  "ModalUpdateWorker.btp.required": "Le N° de carte BTP est obligatoire",
  "ModalUpdateWorker.save": "Enregister",

  "ModalComment.title": "Commenter ma mission",
  "ModalComment.comment": "Commentaire",

  "ModalLocation.title": "Adresse du chantier",
  "ModalLocation.title.address": "Adresse",
  "ModalLocation.title.comment": "Commentaire",

  "ModalInfos.additional.informations": "Informations complémentaires",

  "ModalContacts.title": "Contacts externes",
  "ModalContacts.principle": "Principal",

  "ModalAgence.title": "Agence contractante",
  "ModalAgence.agence": "Agence",
  "ModalAgence.contacts": "Contacts",

  "ModalControl.btpNumber": "N° carte BTP",
  "ModalControl.comment": "Commentaires liés au contrôle",
  "ModalControl.btp.required": "Le N° de carte BTP est obligatoire",
  "ModalControl.save": "Enregister",
  "ModalControl.authorized": "Autorisée",
  "ModalControl.unauthorized": "Non autorisée",
  "ModalControl.pending": "En attente",

  "WorkersPage.new": "Nouveau",
  "WorkersPage.search": "Rechercher",
  "WorkersPage.add": "Ajouter",
  "WorkersPage.no_done": "Liste des travailleurs déclarés",
  "WorkersPage.validated": "Contrôles validés",
  "WorkersPage.approuved": "Contrôles en attente d'approbation",
  "WorkersPage.refused": "Contrôles refusés",
  "WorkersPage.noResults": "Aucun travailleur trouvé",

  "MissionPage.title.workersDeclared": "Travailleurs déclarés",
  "MissionPage.button.search": "Rechercher",
  "MissionPage.button.add": "Ajouter",
  "MissionPage.list.workersDeclared": "Liste des travailleurs déclarés",
  "MissionPage.control.validated": "Contrôles validés",
  "MissionPage.control.approuved": "En attente d'approbation",
  "MissionPage.control.refused": "Contrôles refusés",
  "MissionPage.mission.comment": "Commenter ma mission",
  "MissionPage.mission.end": "Terminer ma visite",
  "MissionPage.rapport.title": "Le rapport suivant a bien été transféré :",
  "MissionPage.rapport.2": "Retrouvez votre rapport stocké sur la plateforme Présence Socotec",
  "MissionPage.rapport.3": "Ajouter vos destinataires client",
  "MissionPage.rapport.4": "Envoyer le rapport de mission au client",
  "MissionPage.button.transfer": "Transférer le rapport",
  "MissionPage.button.listeVisites": "Liste de visites",

  "ReportPage.add": "Ajouter",
  "ReportPage.recipients": "Destinataires",
  "ReportPage.me": "Moi même",
  "ReportPage.contact.principle": "Contact principal",
  "ReportPage.message": "Message",
  "ReportPage.send": "Envoyer le rapport",

  "ModalRecipients.select.all": "Tout sélectionner",
  "ModalRecipients.add": "Ajouter",

  "ModalMissionEnd.content.title": "La mission est terminée ?",
  "ModalMissionEnd.content.text": "Transférez le rapport de la mission sur la plateforme",

  "ReportConfirmationPage.title": "Rapport de chantier",
  "ReportConfirmationPage.ref": "REF",
  "ReportConfirmationPage.send": "Votre rapport a bien été envoyé !",
  "ReportConfirmationPage.home": "Retour à la liste des chantiers"
};
