import React, { useState } from "react";
import ReactModal from "react-modal";
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import classNames from "classnames";
import _ from "lodash";
import ImagePreview from "../../ImagePreview";
import images from "../../../images";

interface Props {
  isOpen: boolean;
  onClick: () => void;
  btpCardNumber: string | undefined
  btpNumber: string;
  dataUri: string;
  setDataUri: React.Dispatch<React.SetStateAction<string>>
  onTakePhoto: (value) => void;
}

const customStyles = {
  content: {
    top: "0%",
    background: "#182B48",
    padding: "20px"
  },
  overlay: {
    zIndex: 99999
  }
};

const ModalCamera = (props: Props) => {
  const [btpCardNumber, setBtpCardNumber] = useState(props.btpCardNumber)
  const btpNumberSet = !_.isEmpty(props.btpNumber);

  const className = classNames("flex flex-row", {
    "pb-4": btpNumberSet,
    "pb-8 pt-4": !btpNumberSet
  });

  const handlePhoto = e => {
    const t = document.getElementById("outer-circle");
    t?.click();
  };

  return (
    <ReactModal
      isOpen={props.isOpen}
      contentLabel="modal-camera"
      appElement={document.getElementById("root")}
      style={customStyles}
    >
      <div className="flex flex-col flex-grow justify-between">
        <div className={className}>
          <div className="flex-1 flex flex-col">
            <span className="text-center text-white font-roboto font-bold text-16px tracking-normal opacity-100">
              Prendre en photo la carte BTP
            </span>
          </div>
          <img
            src={images.retourWhite}
            className="absolute self-center pl-2"
            alt="<"
            onClick={() => {
              props.onClick();
            }}
          />
        </div>
        <div className="flex flex-row justify-center">
          {props.dataUri || btpCardNumber ? (
            <ImagePreview dataUri={btpCardNumber ? btpCardNumber : props.dataUri} isFullscreen={false} />
          ) : (
              <Camera
                onTakePhotoAnimationDone={props.onTakePhoto}
                idealFacingMode={FACING_MODES.ENVIRONMENT}
                imageType={IMAGE_TYPES.JPG}
                isMaxResolution={false}
                isFullscreen={false}
                isImageMirror={false}
              />
            )}
        </div>
        {props.dataUri || btpCardNumber ? (
          <div className="flex flex-row justify-around py-8">
            <img
              src={images.camera}
              alt="Recommencer"
              onClick={() => {
                setBtpCardNumber("")
                props.setDataUri("")
              }}
              width={"75px"}
              height={"75px"}
            />
            <img
              src={images.ok}
              alt="Valider"
              onClick={() => {
                props.onClick();
              }}
              width={"75px"}
              height={"75px"}
            />
          </div>
        ) : (
            <div className="flex flex-row justify-around py-8">
              <img src={images.camera} alt="Valider" onClick={handlePhoto} />
            </div>
          )}
      </div>
    </ReactModal>
  );
};

export default ModalCamera;
