import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppState } from "../../redux/reducers";

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const { authenticated, token, user } = useSelector((state: AppState) => ({
    authenticated: state.authentication.authenticated,
    token: state.authentication.token,
    user: state.authentication.user
  }));

  return (
    <Route
      {...rest}
      render={props =>
        authenticated && token && user ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
