import React, { useState } from "react";
import Autosuggest from "react-autosuggest";
import * as AutosuggestHighlightMatch from "autosuggest-highlight/match";
import * as AutosuggestHighlightParse from "autosuggest-highlight/parse";
import { MessageFormatElement } from 'intl-messageformat-parser';
import classNames from "classnames"
import "./styles.css";
import images from "../../images";

interface Props {
  id?: string
  name?: string
  placeholder: string | MessageFormatElement[];
  suggestions: any;
  keySearch: string;
  keys?: string[];
  onFilter?: (value: string) => void;
  onChange?: (value: string) => void
  onFocus?: () => void
  onBlur?: () => void
  onInit?: () => void;
  theme?: object;
  children?: React.ReactNode;
  className?: string
  value?: string
}

const Autocomplete = (props: Props) => {
  const [value, setValue] = useState(props.value);
  const [suggestions, setSuggestions] = useState([]);

  const escapeRegexCharacters = str => {
    return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  };

  const getSuggestions = (value: string) => {
    const escapedValue = escapeRegexCharacters(value.trim());
    if (escapedValue === "") {
      return [];
    }


    // fuzzy find the value string in the suggestions
    const values = props.suggestions.filter(e => {
      const keys = props.keys || [props.keySearch];
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (e[key].toLowerCase().includes(escapedValue.toLowerCase())) {
          return true;
        }
      }
      return false;
    });
    return values;
  };

  const getSuggestionValue = suggestion => {
    return suggestion[props.keySearch];
  };

  const renderSuggestion = (suggestion, { query }) => {
    const matches = AutosuggestHighlightMatch(
      suggestion[props.keySearch],
      query
    );
    const parts = AutosuggestHighlightParse(
      suggestion[props.keySearch],
      matches
    );

    let infos = "";
    props.keys?.forEach(key => {
      const text = suggestion[key];

      infos += " " + text;
    });

    if (infos) {
      parts.push({
        text: infos,
        highlight: false
      });
    }

    return (
      <span>
        {parts.map((part, index) => {
          const className = part.highlight
            ? "react-autosuggest__suggestion-match"
            : "";

          return (
            <span className={className} key={index}>
              {part.text}
            </span>
          );
        })}
      </span>
    );
  };

  const onChange = (event, { newValue, method }) => {
    setValue(newValue);
    if (props.onChange) props.onChange(newValue)
  };

  const onSuggestionsFetchRequested = ({ value }) => {

    setSuggestions(getSuggestions(value));
    if (props.onInit) {
      props.onInit();
    }
  };

  const onSuggestionsClearRequested = () => {

    setSuggestions([]);
  };

  const onSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    if (props.onFilter) {
      // console.log({
      //   "suggestion ::": suggestion,
      //   "suggestionValue : ": suggestionValue
      // });
      props.onFilter(suggestion);
    }
  };

  const inputProps = {
    id: props.id,
    name: props.name,
    placeholder: props.placeholder,
    value,
    onChange: onChange,
    onFocus: props.onFocus,
    onBlur: props.onBlur,
    autoFocus: false,
    autoComplete: "off",
  };

  return (
    <div className={classNames("flex flex-col relative", props.className)}>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionSelected={onSuggestionSelected}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        theme={props.theme}
      />
      {props.children}
    </div>
  );
};

export default Autocomplete;
