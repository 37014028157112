import { defineMessages } from "react-intl";

const messages = defineMessages({
  title: {
    id: "ModalUpdateWorker.title",
    defaultMessage: "Modifier travailleur"
  },
  firstname: {
    id: "ModalUpdateWorker.firstname",
    defaultMessage: "Prénom"
  },
  lastname: {
    id: "ModalUpdateWorker.lastname",
    defaultMessage: "Nom"
  },
  company: {
    id: "ModalUpdateWorker.company",
    defaultMessage: "Entreprise"
  },
  btpNumber: {
    id: "ModalUpdateWorker.btpNumber",
    defaultMessage: "N° carte BTP"
  },
  takePicture: {
    id: "ModalCreateWorker.takePicture",
    defaultMessage: "Prendre en photo la carte BTP"
  },
  save: {
    id: "ModalUpdateWorker.save",
    defaultMessage: "Enregister"
  },
  firstNameRequired: {
    id: "ModalUpdateWorker.firstname.required",
    defaultMessage: "Le prénom est obligatoire"
  },
  lastNameRequired: {
    id: "ModalUpdateWorker.lastname.required",
    defaultMessage: "Le nom est obligatoire"
  },
  companyNameRequired: {
    id: "ModalUpdateWorker.company.required",
    defaultMessage: "L'entreprise est obligatoire"
  },
  btpNumberRequired: {
    id: "ModalUpdateWorker.btp.required",
    defaultMessage: "Le N° de carte BTP est obligatoire"
  }
});

export default messages;
