import { ErrorResponse } from "../../api/error";
import { GetSiteOpts, ListSitesOpts, Site } from "../../models/Interfaces/Site";

export const GET_SITES = "GET_SITES";
export interface GetSitesAction {
  type: typeof GET_SITES;
  payload: ListSitesOpts;
  meta: any;
}

export const GET_SITES_SUCCESS = "GET_SITES_SUCCESS";
export interface GetSitesSuccessAction {
  type: typeof GET_SITES_SUCCESS;
  payload: Site[];
  meta: any;
}

export const GET_SITES_FAILURE = "GET_SITES_FAILURE";
export interface GetSitesFailureAction {
  type: typeof GET_SITES_FAILURE;
  payload: { status: number; name: string; response: ErrorResponse };
  meta: any;
}

export const GET_SITE = "GET_SITE";
export interface GetSiteAction {
  type: typeof GET_SITE;
  payload: GetSiteOpts;
  meta: any;
}

export const GET_SITE_SUCCESS = "GET_SITE_SUCCESS";
export interface GetSiteSuccessAction {
  type: typeof GET_SITE_SUCCESS;
  payload: Site;
  meta: any;
}

export const GET_SITE_FAILURE = "GET_SITE_FAILURE";
export interface GetSiteFailureAction {
  type: typeof GET_SITE_FAILURE;
  payload: { status: number; name: string; response: ErrorResponse };
  meta: any;
}

export type SiteAction =
  | GetSitesAction
  | GetSitesSuccessAction
  | GetSitesFailureAction
  | GetSiteAction
  | GetSiteSuccessAction
  | GetSiteFailureAction;
