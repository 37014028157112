import React from "react"
import classNames from "classnames"

interface Props {
  children?: React.ReactNode
  className?: string
  stateClassName: string
}

const State = ({ children, className, stateClassName }: Props) => {
  return (<div className={classNames("flex flex-row justify-center items-center text-socotec-gray-100", className)}>
    <span className={classNames("rounded-full", stateClassName)}
      style={{ width: "9px", height: "9px" }}></span>
    <span className="ml-2">{children}</span>
  </div>)
}

export default State