import React, { useState } from "react"
import { useIntl } from "react-intl";
import classNames from "classnames"
import { Site } from "../../models/Interfaces/Site"
import ModalAgence from "../../components/Modals/Agence";
import ModalLocation from "../../components/Modals/Location";
import ModalInfos from "../../components/Modals/Infos";
import ModalContacts from "../../components/Modals/Contacts";
import images from "../../images"

interface Props {
  site: Site;
}

const Menu = ({ site }: Props) => {
  const [showModalLocation, setShowModalLocation] = useState(false);
  const [showModalInfos, setShowModalInfos] = useState(false);
  const [showModalContacts, setShowModalContacts] = useState(false);
  const [showModalAgence, setShowModalAgence] = useState(false);

  const intl = useIntl()

  const closeAllModal = () => {
    setShowModalLocation(false)
    setShowModalInfos(false)
    setShowModalContacts(false)
    setShowModalAgence(false)
  }

  const handleShowModalLocation = () => {
    setShowModalLocation(!showModalLocation)
  };

  const handleShowModalInfos = () => {
    setShowModalInfos(!showModalInfos)
  };

  const handleShowModalContacts = () => {
    setShowModalContacts(!showModalContacts)
  };

  const handleShowModalAgence = () => {
    setShowModalAgence(!showModalAgence)
  }

  const navItemClassName = "flex-1 flex flex-col justify-between border-solid border-1px border-socotec-gray-400 py-3 cursor-pointer"

  return (
    <>
      <div className="flex flex-row bg-socotec-blue-200 fixed w-full bottom-0 z-9999">
        <div
          className={classNames(navItemClassName, { "bg-socotec-blue-400": showModalLocation })}
          onClick={() => {
            closeAllModal()
            handleShowModalLocation()
          }}
        >
          <img src={images.localisation} className="h-6" alt="Localisation" />
          <span className="font-bold font-roboto text-center text-white text-12px tracking-012px opacity-100 pt-1">
            {intl.messages["SitePage.location"]}
          </span>
        </div>
        <div
          className={classNames(navItemClassName, { "bg-socotec-blue-400": showModalInfos })}
          onClick={() => {
            closeAllModal()
            handleShowModalInfos()
          }}
        >
          <img src={images.infos} className="h-6" alt="Infos" />
          <span className="font-bold font-roboto text-center text-white text-12px tracking-012px opacity-100 pt-1">
            {intl.messages["SitePage.infos"]}
          </span>
        </div>
        <div
          className={classNames(navItemClassName, { "bg-socotec-blue-400": showModalContacts })}
          onClick={() => {
            closeAllModal()
            handleShowModalContacts()
          }}
        >
          <img src={images.contact} className="h-6" alt="Contact" />
          <span className="font-bold font-roboto text-center text-white text-12px tracking-012px opacity-100 pt-1">
            {intl.messages["SitePage.contacts"]}
          </span>
        </div>
        <div
          className={classNames(navItemClassName, { "bg-socotec-blue-400": showModalAgence })}
          onClick={() => {
            closeAllModal()
            handleShowModalAgence()
          }}
        >
          <img src={images.agence} className="h-6" alt="Agence" />
          <span className="font-bold font-roboto text-center text-white text-12px tracking-012px opacity-100 pt-1">
            {intl.messages["SitePage.agence"]}
          </span>
        </div>
      </div>

      {
        site && (
          <>
            <ModalLocation
              site={site}
              isOpen={showModalLocation}
              onClick={handleShowModalLocation}
              closeTimeoutMS={500}
            />
            <ModalInfos
              site={site}
              isOpen={showModalInfos}
              onClick={handleShowModalInfos}
              closeTimeoutMS={500}
            />
            <ModalContacts
              site={site}
              isOpen={showModalContacts}
              onClick={handleShowModalContacts}
              closeTimeoutMS={500}
            />
            <ModalAgence
              site={site}
              isOpen={showModalAgence}
              onClick={handleShowModalAgence}
              closeTimeoutMS={500}
            />
          </>
        )
      }
    </>
  )
}

export default Menu