import React from "react"
import { MessageFormatElement } from 'intl-messageformat-parser';
import classNames from "classnames"

interface Props {
  title: string | MessageFormatElement[] | undefined;
  closeButton?: React.ReactNode;
  addonRight?: React.ReactNode
  sticky?: boolean
}

const ModalHeader = ({ title, closeButton, addonRight, sticky }: Props) => {
  const headerClassName = classNames("flex flex-row shadow-sm bg-white",
    {
      "sticky z-3000": sticky
    })

  return (
    <div className={headerClassName} style={{ padding: "20px" }}>
      <div className="flex-1">
        <span className="inline-block w-full text-15px text-center tracking-016px text-socotec-gray-100 font-roboto">
          {title}
        </span>
      </div>

      {closeButton}
      {addonRight}
    </div>
  )
}

export default ModalHeader