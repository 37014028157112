import { Reducer } from "redux";
import * as actions from "./actionTypes";
import { User } from "../../models/Interfaces/User";
import { ErrorResponse } from "../../api/error";
import OfflineDetector from "../../services/OfflineDetector";

export interface AuthenticationState {
  // sign in
  token: string | null;
  refresh_token: string | null;
  authenticated: boolean;
  error: ErrorResponse | null;

  // fetch user
  user: User | null;
}

const authState = localStorage.getItem("reduxPersist:authentication");
const json = authState ? JSON.parse(authState) : {};

const initialState: AuthenticationState = {
  // sign in
  token: json.token,
  refresh_token: json.refresh_token,
  authenticated: json.authenticated,
  error: null,

  // fetch user
  user: json.user
};

const authenticationReducer: Reducer<
  AuthenticationState,
  actions.AuthenticationAction
> = (
  state: AuthenticationState = initialState,
  action: actions.AuthenticationAction
): AuthenticationState => {
    switch (action.type) {
      // generate token and refresh token, optimistically generate token and refresh token without waiting server response
      case actions.SIGN_IN:

        return {
          ...state,
          authenticated: true,
          error: null
        };

      // catch server response and replace local tokens with data received from server response, committing actual success
      case actions.SIGN_IN_SUCCESS:

        return {
          ...state,
          authenticated: true,
          token: action.payload.token,
          refresh_token: action.payload.refresh_token,
          error: null
        };

      // rollback changes by deleting the tokens in case of request failure
      case actions.SIGN_IN_FAILURE:

        return {
          ...state,
          token: null,
          refresh_token: null,
          authenticated: false,
          error: action.payload.response
        };

      // generate token and refresh token, optimistically generate token and refresh token without waiting server response
      case actions.REFRESH_TOKEN:

        return {
          ...state,
          error: null
        };

      // catch server response and replace local tokens with data received from server response, committing actual success
      case actions.REFRESH_TOKEN_SUCCESS:

        return {
          ...state,
          token: action.payload.token,
          refresh_token: action.payload.refresh_token,
          authenticated: true,
          error: null
        };

      // rollback changes by deleting the tokens in case of request failure
      case actions.REFRESH_TOKEN_FAILURE:

        return {
          ...state,
          authenticated: false,
          error: action.payload
        };

      // fetching user
      case actions.GET_ME:

        return {
          ...state,
          error: null
        };

      // server responded successfully, committing data given by the server
      case actions.GET_ME_SUCCESS:

        return {
          ...state,
          user: action.payload,
          error: null
        };

      // server fails to respond
      case actions.GET_ME_FAILURE:

        return {
          ...state,
          user: null,
          error: action.payload.response
        };

      // server fails to respond
      case actions.SIGN_OUT:

        const nextToken = (OfflineDetector.appIsOnline()) ? null : state.token
        return {
          ...state,
          authenticated: false,
          token: nextToken,
          error: null
        };

      default:
        return state;
    }
  };

export default authenticationReducer;
