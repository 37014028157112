import React from "react";
import * as Sentry from "@sentry/browser";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Detector, Online } from "react-detect-offline";
import ReactNotification from "react-notifications-component";
import { IntlProvider } from "react-intl";
import moment from "moment";
import "moment/locale/fr";
import * as serviceWorker from "./serviceWorker";
import AppLocale from "./lang";
import store from "./redux/store";
import App from "./App";
import { Spinner } from "./components/Spinner";
import {
  showOfflineNotification,
  showOnlineNotification
} from "./utils/Notification";
import OfflineDetector from "./services/OfflineDetector";

import "react-notifications-component/dist/theme.css";
import "animate.css";
import "react-html5-camera-photo/build/css/index.css";
import "rc-swipeout/assets/index.css";

import "./index.css";
import "./styles/tailwind.css";


Sentry.init({ dsn: process.env.GLITCHTIP_DSN_ADRESS });

const currentAppLocale = AppLocale["fr"];

moment.locale("fr");

OfflineDetector.subscribe({
  uniqueName: "App", function: online => {
    if (!online) {
      showOfflineNotification(
        "Votre travail sera synchronisé lorsque vous serez à nouveau connecté"
      );
    } else {
      showOnlineNotification(" ");
    }
  }
});

ReactDOM.render(
  <Provider store={store}>
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >

      <ReactNotification />
      <App />
    </IntlProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();


