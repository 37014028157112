import React from "react"
import { useIntl } from "react-intl"
import ReactModal from "react-modal"
import { User } from "../../../models/Interfaces/User"
import InputText from "../../Input/Text"
import ModalHeader from "../ModalHeader"
import ModalClose from "../ModalClose"
import ModalContent from "../ModalContent"
import images from "../../../images"

interface Props {
  isOpen: boolean
  user: User
  onClick?: () => void
}

const customStyles = {
  content: {
    top: 0,
    padding: 0,
    background: "#F5F7FC"
  },
  overlay: {
    zIndex: 9999
  }
};

const ModalAccount = ({ isOpen, user, onClick }: Props) => {
  const intl = useIntl()

  const closeModal = <ModalClose onClick={onClick} icon={images.arrowLeft} />

  return (
    <ReactModal
      isOpen={isOpen}
      contentLabel="modal-account"
      appElement={document.getElementById("root")}
      style={customStyles}
    >
      <ModalHeader
        sticky
        title={intl.messages["ModalAccount.title"]}
        closeButton={closeModal}
      />
      <ModalContent>
        <div className="text-18px text-socotec-gray-100 font-bold mb-4">{intl.messages["ModalAccount.account"]}</div>

        <div className="py-4">
          <InputText
            id="prenom"
            name="prenom"
            type="text"
            placeholder=""
            label="Prénom"
            disabled={true}
            value={user.firstname}
            className="mb-8"
          />
          <InputText
            id="nom"
            name="nom"
            type="text"
            placeholder=""
            label="Nom"
            disabled={true}
            value={user.lastname}
            className="mb-8"
          />
          <InputText
            id="mail"
            name="mail"
            type="text"
            placeholder=""
            label="Mail"
            disabled={true}
            value={user.email}
            className="mb-8"
          />
          <InputText
            id="tel"
            name="tel"
            type="text"
            placeholder=""
            label="Téléphone"
            disabled={true}
            value={user.phone}
            className="mb-8"
          />
        </div>
      </ModalContent>
    </ReactModal>
  )
}

export default ModalAccount