import _ from "lodash";
import { Worker } from "../../models/Interfaces/Worker";
import { status as ControlStatus } from "../../enums/control";

export const getAllWorkers = (workers: Worker[], mid: string | undefined) => {
  return workers;
};

export const getCheckedWorkers = (
  workers: Worker[],
  mid: string | undefined
) => {
  const items = workers?.filter(w => {
    const results =
      w && w.controles && w.controles.length > 0
        ? w.controles?.filter(c => c.status === ControlStatus.VALIDATED && c.mission?.id == mid)
        : [];
    return !_.isEmpty(results);
  });

  return items;
};

export const getApprouvedWorkers = (
  workers: Worker[],
  mid: string | undefined
) => {
  const items = workers?.filter(w => {
    const results =
      w && w.controles && w.controles.length > 0
        ? w.controles?.filter(
          // @todo: check against comment for test prupose
          c => c.status === ControlStatus.APPROUVED && c.mission?.id == mid
        )
        : [];
    return !_.isEmpty(results);
  });
  return items;
};

export const getRefusedWorkers = (
  workers: Worker[],
  mid: string | undefined
) => {
  const items = workers?.filter(w => {
    const results =
      w && w.controles && w.controles.length > 0
        ? w.controles?.filter(c => c.status === ControlStatus.REFUSED && c.mission?.id == mid)
        : [];
    return !_.isEmpty(results);
  });
  return items;
};

export const getNotControlledWorkers = (
  workers: Worker[],
  mid: string | undefined
) => {
  const items = workers?.filter(worker => {
    return worker && worker.controles && worker.controles.length > 0
      ? worker.controles?.find(control => control.mission?.id == mid) === undefined
      : true
  })

  return items
}