import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import ReactModal from "react-modal";
import moment from "moment";
import _ from "lodash";
import classNames from "classnames"
import { Formik, FormikProps, Field } from "formik";
import * as Yup from "yup";
import { useIntl } from "react-intl";
import messages from "./messages";
import Button from "../../Button";
import * as workerActions from "../../../redux/Worker/actions";
import { Mission } from "../../../models/Interfaces/Mission";
import { Worker } from "../../../models/Interfaces/Worker";
import { status as ControlStatus } from "../../../enums/control";
import {
  showSuccessNotification,
  showErrorNotification,
  showTreatmentNotification
} from "../../../utils/Notification";
import { Site } from "../../../models/Interfaces/Site";
import { State } from "../../../components/General"
import ModalHeader from "../ModalHeader"
import ModalClose from "../ModalClose"
import images from "../../../images"
import { useThunkDispatch } from "../../../redux/hooks";
import OfflineDetector from "../../../services/OfflineDetector";

interface Props {
  isOpen: boolean;
  onClick: () => void;
  onEdit: () => void;
  site: Site;
  mission: Mission;
  worker: Worker;
  query: string;
}

const customStyles = {
  content: {
    top: 0,
    padding: 0,
    background: "#ffffff"
  },
  overlay: {
    zIndex: 9999
  }
};

const ModalControl = (props: Props) => {
  const [dataUri, setDataUri] = useState("");
  const [hasFocusedField, setHasFocusedField] = useState<undefined | boolean>()
  const refForm = useRef<FormikProps<any>>()

  const dispatch = useThunkDispatch();
  const intl = useIntl();

  const validationSchema = Yup.object({
    btp: Yup.string() //.required(intl.formatMessage(messages.btpNumberRequired))
  });

  let control;
  if (props.worker && props.mission && !_.isEmpty(props.worker.controles)) {
    control = props.worker.controles?.find(
      e => e.mission && e.mission.id == props.mission.id
    );
  }

  const query = props.query;

  const handleUpdateControl = (
    values: {
      btp: string;
      comment: string;
      status: string;
    },
    setSubmitting: any
  ) => {

    if (control) {
      const payload = {
        id: control.id,
        btp_number: values.btp,
        comment: values.comment,
        status: values.status,
        btp_card_image: dataUri,
        site: props.site,
        worker: props.worker,
        mission: props.mission
      };

      if (OfflineDetector.appIsOnline()) {
        dispatch(workerActions.updateControlDispatch(payload, control, true))
          .then(res => {
            showSuccessNotification("Le contrôle a été mis à jour avec succès");
          })
          .catch(err => {
            showErrorNotification("Le contrôle n'a pas été mis à jour");
          });
        props.onClick();
      } else {
        dispatch(workerActions.updateControlDispatch(payload, control, true));
        props.onClick();
      }
    } else {
      // no control found for this worker and this mission, we will create him a control
      const payload = {
        btp_number: values.btp,
        comment: values.comment,
        status: values.status,
        worker: props.worker,
        mission: props.mission,
        btp_card_image: dataUri,
        site: props.site
      };

      if (OfflineDetector.appIsOnline()) {
        dispatch(workerActions.createControlDispatch(payload, true))
          .then(res => {
            showTreatmentNotification("TRAVAILLEUR CONTROLÉ");
          })
          .catch(err => {
            showErrorNotification("Le contrôle n'a pas été crée");
          });
        props.onClick();
      } else {
        dispatch(workerActions.createControlDispatch(payload, true));
        props.onClick();
      }
    }
  };

  const handleCommentChange = (e, setFieldValue) => {
    setFieldValue("comment", e.target.value, false);
  };

  const handleControlChange = (e, setFieldValue) => {
    setFieldValue("status", e, false);
  };

  const lastControlDate = control ? moment(props.worker.last_controle_date).format("L") : "";

  const handleHasFocusedField = () => {
    setHasFocusedField(true)
  }

  const handleHasnotFocusedField = () => {
    setHasFocusedField(false)
  }

  const closeModal = <ModalClose onClick={props.onClick} />

  const modalTitle = control ? "Contrôle" : "Contrôles non effectués"

  const pictureOk = props.worker && props.worker.btp_card_image;

  const addonRightClassName = classNames("absolute right-20px self-center text-socotec-blue-100 text-15px", {
    "opacity-0": hasFocusedField === undefined,
    "hidden-with-delay": hasFocusedField === false
  })

  const addonRight = <div className={addonRightClassName} onClick={refForm.current?.submitForm}>{intl.formatMessage(messages.save)}</div>

  return (
    <ReactModal
      isOpen={props.isOpen}
      contentLabel="modal-worker-new"
      appElement={document.getElementById("root")}
      style={customStyles}
    >
      <ModalHeader sticky title={modalTitle} closeButton={closeModal} addonRight={addonRight} />

      <div className="flex flex-row my-6 mx-2">
        <div className="mx-2">
          {pictureOk && <img src={images.picture} alt="#" />}
          {!pictureOk && <div className="rounded bg-socotec-gray-100 py-3 px-5"><img src={images.plusWhite} className="fill-current text-white" alt="+" /></div>}
        </div>
        <div className="flex flex-col mx-2">
          <span className="text-13px uppercase tracking-078px text-socotec-gray-900 mb-1">{props.worker.company.name}</span>
          <span className="text-18px text-socotec-gray-100">
            {props.worker.firstname} {props.worker.lastname}
          </span>
          <span className="text-13px text-socotec-gray-100 mb-1">{props.worker.btp_number}</span>
          <span className="text-socotec-blue-100 text-15px tracking-015px" onClick={() => {
            props.onClick();
            props.onEdit();
          }}>Modifier</span>
        </div>
      </div>

      <Formik
        initialValues={{
          btp: props.worker.btp_number,
          comment: control ? control.comment : "",
          status: control ? control.status : null,
          companyStatus: props.worker.company.status || "pending"
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleUpdateControl(values, setSubmitting);
        }}
      >
        {({
          handleSubmit,
          setFieldValue,
          isSubmitting,
          values,
        }) => {
          const controlStatusClassname = control ? classNames({
            "bg-socotec-green-100": control.status == ControlStatus.VALIDATED,
            "bg-socotec-red-100": control.status == ControlStatus.REFUSED,
          }) : ""

          return (
            <form
              className="flex flex-col flex-grow justify-between"
              onSubmit={handleSubmit}
              style={{ paddingBottom: "60px" }}
            >
              <div className="flex flex-col my-2 bg-socotec-white-100 p-4">
                <div className="flex flex-col relative">
                  <div className="text-18px font-bold text-socotec-gray-100 mb-2">
                    Commentaires
                  </div>
                  <textarea
                    id="comment"
                    name="comment"
                    value={values.comment}
                    placeholder="Je commente ma mission"
                    onChange={e => handleCommentChange(e, setFieldValue)}
                    onFocus={handleHasFocusedField}
                    onBlur={handleHasnotFocusedField}
                    cols={40}
                    rows={5}
                    className="relative w-full font-roboto text-16px text-socotec-gray-100 bg-socotec-white-100 border-none outline-none"
                  />
                </div>
              </div>

              {values.companyStatus && (
                <div className="flex flex-row space-x-2 text-gray-700 bg-orange-100 px-3 py-2">
                  <img src={images.infosBulle} alt="Info" width="22px" />
                  <div>
                    L'entreprise sélectionnée a le statut <strong>{intl.formatMessage(messages[values.companyStatus || "pending"])}</strong>. Veuillez en tenir compte lors de la validation du contrôle.
                  </div>
                </div>
              )}


              <div className="flex flex-col mt-8 px-10 pb-8">
                {control && (control.status == ControlStatus.VALIDATED || control.status == ControlStatus.REFUSED) && (
                  <State stateClassName={controlStatusClassname} className="mb-8">
                    <span className="text-15px">{control.status == ControlStatus.VALIDATED ? "Validé" : "Refusé"} le {lastControlDate}</span>
                  </State>
                )}

                <div role="group" className="flex flex-col flex-wrap content-around justify-center space-y-6 pb-6">
                  <Field id={ControlStatus.VALIDATED} type="radio" name="status" className="hidden radio-valid" value={ControlStatus.VALIDATED} onChange={() => handleControlChange(ControlStatus.VALIDATED, setFieldValue)} />
                  <label htmlFor={ControlStatus.VALIDATED} className="valid inline-flex justify-between p-3 w-full text-socotec-gray-100 bg-white rounded-lg border hover:text-green-600 hover:bg-green-100">
                    Validé
                  </label>
                  <Field id={ControlStatus.APPROUVED} type="radio" name="status" className="hidden radio-approuved" value={ControlStatus.APPROUVED} onChange={() => handleControlChange(ControlStatus.APPROUVED, setFieldValue)} />
                  <label htmlFor={ControlStatus.APPROUVED} className="approuved inline-flex justify-between p-3 w-full text-socotec-gray-100 bg-white rounded-lg border hover:text-orange-600 hover:bg-orange-100">
                    En attente d'approbation
                  </label>
                  <Field id={ControlStatus.REFUSED} type="radio" name="status" className="hidden radio-refused" value={ControlStatus.REFUSED} onChange={() => handleControlChange(ControlStatus.REFUSED, setFieldValue)} />
                  <label htmlFor={ControlStatus.REFUSED} className="refused inline-flex justify-between p-3 w-full text-socotec-gray-100 bg-white rounded-lg border hover:text-red-600 hover:bg-red-100">
                    Refusé
                  </label>
                </div>
              </div>

              {hasFocusedField !== true && (
                <div className="fixed bottom-20px left-10 right-10" style={{ width: "calc(100% - 5rem)" }}>
                  <Button disabled={isSubmitting}>
                    <span className="font-roboto">
                      {intl.formatMessage(messages.save)}
                    </span>
                  </Button>
                </div>
              )}
            </form>
          )
        }}
      </Formik>
    </ReactModal>
  )
};

export default ModalControl;
