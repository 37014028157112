import React from "react";
import classNames from "classnames"

interface Props {
  children: React.ReactNode;
  state?: string;
  className?: string;
  onClick?: () => void;
}

const Badge = ({ children, state, className, onClick }: Props) => {
  let badgeClassName = classNames(className, "rounded-full text-12px")

  // @todo: refactor enum
  switch (state) {
    case 'inprogress':
      badgeClassName = classNames(badgeClassName, "bg-socotec-green-100 text-white")
      break;
    default:
      badgeClassName = classNames(badgeClassName, "bg-white text-socotec-gray-100")
      break;
  }

  return (
    <div className={badgeClassName} style={{ padding: "2px 10px" }} onClick={onClick}>
      {children}
    </div>
  )
}

export default Badge