import { defineMessages } from "react-intl";

const messages = defineMessages({
  title: {
    id: "ModalCreateWorker.title",
    defaultMessage: "Nouveau travailleur"
  },
  firstname: {
    id: "ModalCreateWorker.firstname",
    defaultMessage: "Prénom"
  },
  lastname: {
    id: "ModalCreateWorker.lastname",
    defaultMessage: "Nom"
  },
  company: {
    id: "ModalCreateWorker.company",
    defaultMessage: "Entreprise"
  },
  btpNumber: {
    id: "ModalCreateWorker.btpNumber",
    defaultMessage: "N° carte BTP"
  },
  takePicture: {
    id: "ModalCreateWorker.takePicture",
    defaultMessage: "Prendre en photo la carte BTP"
  },
  check: {
    id: "ModalCreateWorker.check",
    defaultMessage: "Créer"
  },
  firstNameRequired: {
    id: "ModalCreateWorker.firstname.required",
    defaultMessage: "Le prénom est obligatoire"
  },
  lastNameRequired: {
    id: "ModalCreateWorker.lastname.required",
    defaultMessage: "Le nom est obligatoire"
  },
  companyNameRequired: {
    id: "ModalCreateWorker.company.required",
    defaultMessage: "L'entreprise est obligatoire"
  },
  btpNumberRequired: {
    id: "ModalCreateWorker.btp.required",
    defaultMessage: "Le N° de carte BTP est obligatoire"
  },
  authorized: {
    id: "ModalCreateWorker.authorized",
    defaultMessage: "Autorisée"
  },
  unauthorized: {
    id: "ModalCreateWorker.unauthorized",
    defaultMessage: "Non autorisée"
  },
  pending: {
    id: "ModalCreateWorker.pending",
    defaultMessage: "En attente"
  },
});

export default messages;
