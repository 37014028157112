import moment from "moment";
import { Mission } from "../../models/Interfaces/Mission";
import { Site } from "../../models/Interfaces/Site";
import { Worker } from "../../models/Interfaces/Worker";

export const sortSitesByDate = (sites: Site[]) => {
  return sites.sort((a: Site, b: Site) => {
    if (a.updated_at && b.updated_at) {
      const date1 = moment(a.updated_at);
      const date2 = moment(b.updated_at);
      return date2.diff(date1);
    }
    if (a.created_at && b.created_at) {
      const date1 = moment(a.created_at);
      const date2 = moment(b.created_at);
      return date2.diff(date1);
    }
    return -1;
  });
};

export const sortMissionsByDate = (missions?: Mission[]) => {
  return missions?.sort((a: Mission, b: Mission) => {
    if (a.updated_at && b.updated_at) {
      const date1 = moment(a.updated_at);
      const date2 = moment(b.updated_at);
      return date2.diff(date1);
    }
    if (a.created_at && b.created_at) {
      const date1 = moment(a.created_at);
      const date2 = moment(b.created_at);
      return date2.diff(date1);
    }
    return -1;
  });
};

export const sortWorkersByDate = (workers: Worker[]) => {
  const addWorkersInOffline =
    workers && workers.length > 0
      ? workers
          .filter(w => w.temp)
          .sort((a: Worker, b: Worker) => {
            if (a.updated_at && b.updated_at) {
              const date1 = moment(a.updated_at);
              const date2 = moment(b.updated_at);
              return date2.diff(date1);
            }
            if (a.created_at && b.created_at) {
              const date1 = moment(a.created_at);
              const date2 = moment(b.created_at);
              return date2.diff(date1);
            }
            return -1;
          })
      : [];

  const others =
    workers && workers.length > 0
      ? workers
          .filter(w => !w.temp)
          .sort((a: Worker, b: Worker) => {
            if (a.id < b.id) {
              return 1;
            }
            if (a.id > b.id) {
              return -1;
            }
            return 0;
          })
      : [];

  return addWorkersInOffline.concat(others);
};

export const sortWorkersByName = (workers: Worker[]) => {
  return workers && workers.length > 0
      ? workers
        .sort((a: Worker, b: Worker) => {
            let aName = a.firstname.toLowerCase(), bName = b.firstname.toLowerCase()
            if (aName < bName) {
              return -1;
            }
            if (aName > bName) {
              return 1;
            }
            return 0;
          })
      : [];
};

export const sortWorkersByCompany = (workers: Worker[]) => {
   return workers && workers.length > 0
      ? workers
         .sort((a: Worker, b: Worker) => {          
            let aCompany = a.company.name.toLowerCase(), bCompany = b.company.name.toLowerCase()
            if (aCompany < bCompany) {
              return -1;
            }
            if (aCompany > bCompany) {
              return 1;
            }
            return 0;
          })
      : [];
};