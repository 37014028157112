const status = {
  NEW: "NEW",
  PENDING: "PENDING",
  PUBLISHED: "PUBLISHED",
  CLOSED: "CLOSED",
  ARCHIVED: "ARCHIVED"
};

export const isNew = (site: any) => {
  return site && site.status === status.NEW;
};

export const isPending = (site: any) => {
  return site && site.status === status.PENDING;
};

export const isPublished = (site: any) => {
  return site && site.status === status.PUBLISHED;
};

export const isClosed = (site: any) => {
  return site && site.status === status.CLOSED;
};

export const isArchived = (site: any) => {
  return site && site.status === status.ARCHIVED;
};

export default status;
