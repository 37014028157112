import { Reducer } from "redux";

import * as actions from "./actionTypes";
import { Site } from "../../models/Interfaces/Site";
import { ErrorResponse } from "../../api/error";
import { sortSitesByDate, sortMissionsByDate } from "../../utils/Sort";

export interface SiteState {
  sites: Site[];
  site: Site | null;
  error: ErrorResponse | null;
}

const initialState: SiteState = {
  sites: [],
  site: null,
  error: null
};

const siteReducer: Reducer<SiteState, actions.SiteAction> = (
  state: SiteState = initialState,
  action: actions.SiteAction
): SiteState => {
  // fetched site
  const sites = [...state.sites];

  switch (action.type) {
    case actions.GET_SITES:

      return {
        ...state,
        error: null
      };

    case actions.GET_SITES_SUCCESS:

      return {
        ...state,
        sites: sortSitesByDate(action.payload),
        error: null
      };

    case actions.GET_SITES_FAILURE:

      return {
        ...state,
        error: action.payload.response
      };

    case actions.GET_SITE:

      const s =
        sites && sites.length > 0
          ? (sites.find(e => e.id == action.payload.id) as Site)
          : null;

      return {
        ...state,
        sites: sites,
        site: s,
        error: null
      };

    case actions.GET_SITE_SUCCESS:

      action.payload.missions = sortMissionsByDate(action.payload.missions);

      // find site and replace it
      const index = sites.findIndex(e => e.id == action.meta.id);
      if (sites && sites.length > 0 && index >= 0) {
        sites[index] = action.payload;
      }

      return {
        ...state,
        sites: sites,
        site: action.payload,
        error: null
      };

    case actions.GET_SITE_FAILURE:

      return {
        ...state,
        error: action.payload.response
      };

    default:
      return state;
  }
};

export default siteReducer;
