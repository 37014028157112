import { store as storeNotification } from "react-notifications-component";

const showNotification = (title: string, message: string, type: string) => {
  storeNotification.addNotification({
    //isMobile: true,
    title: title,
    message: message,
    type: type,
    insert: "top",
    container: "bottom-center",
    animationIn: ["animated", "fadeIn"], // animate.css classes that's applied
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: 3000
      //showIcon: true
    }
  });
};

export const showSuccessNotification = (message: string) => {
  showNotification("Opération reussie", message, "default");
};

export const showErrorNotification = (message: string) => {
  showNotification("Erreur", message, "danger");
};

export const showOnlineNotification = (message: string) => {
  showNotification("Vous êtes connecté à internet !", message, "default");
};

export const showOfflineNotification = (message: string) => {
  showNotification("Vous n'êtes pas connecté à internet !", message, "danger");
};

export const showTreatmentNotification = (message: string) => {
  showNotification("", message, "success");
};
