import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { injectIntl, useIntl } from "react-intl";
import _ from "lodash";
import { useHistory, useParams } from "react-router-dom";
import images from "../../images";
import * as siteActions from "../../redux/Site/actions";
import * as missionActions from "../../redux/Mission/actions";
import { Header, Sticky, ButtonBack } from "../../components/General"
import Card from "../../components/Card"
import Badge from "../../components/Badge"
import Menu from "../../components/Menu"
import status from "../../enums/mission";
import {
  showErrorNotification,
  showSuccessNotification
} from "../../utils/Notification";
import { AppState } from "../../redux/reducers";
import ModalConfirm from "../../components/Modals/Confirm";
import { useThunkDispatch } from "../../redux/hooks";
import OfflineDetector from "../../services/OfflineDetector";

const Site = () => {
  const [showModalConfirm, setShowModalConfirm] = useState(false)

  const { id, mid } = useParams();
  const dispatch = useThunkDispatch();
  const history = useHistory();
  const intl = useIntl();



  const { site } = useSelector((state: AppState) => ({
    site: state.site.site,
    error: state.site.error
  }));

  const user = useSelector((state: AppState) => state.authentication.user);

  useEffect(() => {
    handleGetSite();
    return () => { };
  }, []);

  const handleGetSite = () => {
    if (id) {
      dispatch(siteActions.getSiteDispatch({ id: id }))
        .then(res => { })
        .catch(err => {
          showErrorNotification(
            "Une erreur s'est produite lors du chargement du chantier"
          );
        });
    }
  };

  const handleCreateMission = () => {
    if (id) {
      const payload = {
        status: status.PENDING,
        comment: "",
        site: {
          id: id
        }
      };

      if (OfflineDetector.appIsOnline()) {
        dispatch(missionActions.createMissionDispatch(payload))
          .then(res => {
            showSuccessNotification("La mission a été demarré avec succès");
            const id = localStorage.getItem("mission_id");
            localStorage.removeItem("mission_id");

            // history push
            history.push(`/site/${site.id}/${id}`);
          })
          .catch((err: any) => {
            showErrorNotification("La mission n'a pas été démarrée");
          });
      } else {
        dispatch(missionActions.createMissionDispatch(payload));
        const id = localStorage.getItem("mission_id");
        localStorage.removeItem("mission_id");
        // history push
        history.push(`/site/${site.id}/${id}`);
      }
    }
  };

  const handleShowModalConfirm = () => {
    setShowModalConfirm(!showModalConfirm)
  }

  let mission;
  if (site) {
    if (!mid) {
      if (!_.isEmpty(site.missions)) {
        // check if user has active mission
        mission = site.missions.find(mission => mission.status === status.PENDING && mission.author.id === user.id)
        // else check if another user got active mission
        if (mission === undefined) {
          mission = mission = site.missions.find(mission => mission.status === status.PENDING)
        }
      }
    } else {
      if (site.missions && !_.isEmpty(site.missions)) {
        mission = site.missions.find(e => e.id == mid);
      }
    }
  }

  let iamAuthor = false;
  if (user && mission && mission.author) {
    iamAuthor = user.id === mission.author.id;
  }

  const startTitle = iamAuthor && mission ? intl.messages["SitePage.card.resumeTitle"] : intl.messages["SitePage.card.startTitle"]

  const contentCardStartVisite = (<>
    <div style={{ marginBottom: "20px" }}><img src={images.casque} alt="" /></div>
    <div className="text-socotec-gray-100 text-center font-bold text-20px">{startTitle}<br />{intl.messages["SitePage.card.startText"]}</div>
  </>)

  const inProgressBadge = <Badge className="absolute top-20px right-20px" state="inprogress">{intl.messages["Badge.PENDING"]}</Badge>



  const pathSite = '/site/' + id
  const pathSiteWorkers = pathSite + '/workers'

  const canHandleCreateMission = site && !mission

  const IconHeader = <ButtonBack onClick={() => {
    history.push({
      pathname: '/home',
      state: { transition: "slideRight" }
    });
  }}></ButtonBack>

  return (
    <div className="flex flex-col w-full min-h-screen bg-socotec-white-100" style={{ paddingBottom: "85px" }}>
      <Sticky className="flex flex-col bg-socotec-white-100" style={{ marginBottom: "20px" }}>
        {site && (<Header title={site.name} icon={IconHeader} />)}
      </Sticky>

      <div className="flex flex-col flex-grow px-4">
        <Card onClick={() => {
          history.push(pathSiteWorkers);
        }}>
          <div style={{ marginBottom: "20px" }}><img src={images.fiche} alt="" /></div>
          <div className="text-socotec-gray-100 text-center font-bold text-20px">{intl.messages["SitePage.card.completeTitle"]}<br />{intl.messages["SitePage.card.completeText"]}</div>
        </Card>
        {
          canHandleCreateMission && (
            <Card onClick={handleShowModalConfirm}>
              {contentCardStartVisite}
            </Card>
          )
        }
        {
          iamAuthor && mission && (
            <Card badge={inProgressBadge} onClick={() => {
              history.push(pathSite + '/' + mission.id)
            }}>
              {contentCardStartVisite}
            </Card>
          )
        }
        {
          site && mission && !iamAuthor && (
            <Card disabled badge={inProgressBadge}>
              {contentCardStartVisite}
            </Card>
          )
        }
      </div>

      <Menu site={site}></Menu>

      <ModalConfirm
        isOpen={showModalConfirm}
        onValid={handleCreateMission}
        onCancel={handleShowModalConfirm}
        textTitle={intl.messages["SitePage.modal.confirm.text"]}
        textValid={intl.messages["SitePage.modal.confirm.button.start"]}
        textCancel={intl.messages["SitePage.modal.confirm.button.cancel"]}
      />
    </div >
  );
};

export default injectIntl(Site);
