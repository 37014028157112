import { SignInOpts } from "../../models/Authentication";
import { ErrorResponse } from "../../api/error";
import { User } from "../../models/Interfaces/User";

export const SIGN_IN = "SIGN_IN";
export interface SignInAction {
  type: typeof SIGN_IN;
  credentials: SignInOpts;
  meta: any;
}

export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export interface SignInSuccessAction {
  type: typeof SIGN_IN_SUCCESS;
  payload: { token: string; refresh_token: string };
  meta: any;
}

export const SIGN_IN_FAILURE = "SIGN_IN_FAILURE";
export interface SignInFailureAction {
  type: typeof SIGN_IN_FAILURE;
  payload: { status: number; name: string; response: ErrorResponse };
  meta: any;
}

export const REFRESH_TOKEN = "REFRESH_TOKEN";
export interface RefreshTokenAction {
  type: typeof REFRESH_TOKEN;
  token: string;
}

export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export interface RefreshTokenSuccessAction {
  type: typeof REFRESH_TOKEN_SUCCESS;
  payload: { token: string; refresh_token: string };
}

export const REFRESH_TOKEN_FAILURE = "REFRESH_TOKEN_FAILURE";
export interface RefreshTokenFailureAction {
  type: typeof REFRESH_TOKEN_FAILURE;
  payload: any;
}

export const GET_ME = "GET_ME";
export interface GetMeAction {
  type: typeof GET_ME;
  meta: any;
}

export const GET_ME_SUCCESS = "GET_ME_SUCCESS";
export interface GetMeSuccessAction {
  type: typeof GET_ME_SUCCESS;
  payload: User;
  meta: any;
}

export const GET_ME_FAILURE = "GET_ME_FAILURE";
export interface GetMeFailureAction {
  type: typeof GET_ME_FAILURE;
  payload: { status: number; name: string; response: ErrorResponse };
  meta: any;
}

export const SIGN_OUT = "SIGN_OUT";
export interface SignOutAction {
  type: typeof SIGN_OUT;
}

export type AuthenticationAction =
  | SignInAction
  | SignInSuccessAction
  | SignInFailureAction
  | RefreshTokenAction
  | RefreshTokenSuccessAction
  | RefreshTokenFailureAction
  | GetMeAction
  | GetMeSuccessAction
  | GetMeFailureAction
  | SignOutAction;
