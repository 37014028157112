
type Callback = { uniqueName: string, function: (online: boolean) => void }

class OfflineDetector {

  private pollUrl: string;
  private interval: number;
  public online: boolean = false;
  private callbacks: Callback[] = [];

  constructor() {
    this.pollUrl = `${process.env.REACT_APP_BACKEND_URL}/health`;
    this.interval = 7000;
    this.pollFunction();
    this.startPolling();
  }

  private startPolling() {
    setInterval(() => {
      this.pollFunction();
    }, this.interval);
  }

  private pollFunction() {
    fetch(this.pollUrl, { method: 'GET' })
      .then(() => {
        if (!this.online) {
          this.online = true;
          this.notifySubscribers();

        }
      })
      .catch(() => {
        if (this.online) {
          this.online = false;
          this.notifySubscribers();
        }
      });
  }

  public subscribe(callback: Callback) {
    if (this.callbacks.find(cb => cb.uniqueName === callback.uniqueName)) {
      return;
    }
    this.callbacks.push(callback);
  }

  public unsubscribe(uniqueName: string) {
    this.callbacks = this.callbacks.filter(cb => cb.uniqueName !== uniqueName);
  }

  public appIsOnline(): boolean {
    return this.online;
  }

  private notifySubscribers() {
    this.callbacks.forEach(callback => callback.function(this.online));
  }

}

export default new OfflineDetector();