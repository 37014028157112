import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import PrivateRoute from "./PrivateRoute";
import Login from "../pages/Login";
import Home from "../pages/Home";
import Site from "../pages/Site";
import Workers from "../pages/Workers";
import Mission from "../pages/Mission";
import Missions from "../pages/Missions";
import Report from "../pages/Report";
import ReportConfirmation from "../pages/ReportConfirmation";
import Dashboard from "../pages/Dashboard";

const AppRoutes = ({ location }) => {
  return (
    <TransitionGroup
      className="relative"
      childFactory={child =>
        React.cloneElement(child, {
          classNames: location.state?.transition ? location.state.transition : "fade",
          timeout: location.state?.duration ? location.state.duration : 300
        })
      }>
      <CSSTransition
        key={location.key}
        timeout={500}
      >
        <div className="absolute top-0 left-0 w-full">
          <Switch location={location}>
            <Route path="/health">
                <h3>Service Online</h3>
            </Route>
            <PrivateRoute exact path="/" component={Dashboard} />
            <Route exact path="/login" component={Login} />
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <PrivateRoute exact path="/home" component={Home} />
            <PrivateRoute exact path="/site/:id/missions" component={Missions} />
            <PrivateRoute exact path="/site/:id/workers" component={Workers} />
            <PrivateRoute exact path="/site/:id" component={Site} />
            <PrivateRoute exact path="/site/:id/:mid" component={Mission} />
            <PrivateRoute exact path="/site/:id/:mid/workers" component={Workers} />
            <PrivateRoute exact path="/report/:id/:mid" component={Report} />
            <PrivateRoute
              exact
              path="/report/:id/:mid/confirmation"
              component={ReportConfirmation}
            />
            <Redirect to="/dashboard" />
          </Switch>
        </div>
      </CSSTransition>
    </TransitionGroup>
  )
}

export default withRouter(AppRoutes);
