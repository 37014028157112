import { Mission } from "../models/Interfaces/Mission";

const status = {
  PENDING: "inprogress",
  CLOSED: "finished",
  REPORT_SENT: "report_sent"
};

export const isPending = (mission: Mission) => {
  return mission && mission.status === status.PENDING;
};

export const isClosed = (mission: Mission) => {
  return mission && mission.status === status.CLOSED;
};

export const isReportSent = (mission: Mission) => {
  return mission && mission.status === status.REPORT_SENT;
};

export default status;
