import React, { useState } from "react";
import classNames from "classnames"
import images from "../../../images"

interface Props {
  placeholder: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  onChange?: (event) => void;
  small?: boolean
}

const InputSearch = ({ placeholder, setSearch, small }: Props) => {
  const [timer, setTimer] = useState()

  let value = ""

  const triggerChange = () => {
    setSearch(value.toLowerCase())
  }

  const handleSearch = event => {
    clearTimeout(timer)

    value = event.target.value

    setTimer(setTimeout(triggerChange, 500))
  }

  const inputClassName = classNames("bg-white font-roboto text-socotec-gray-600 w-full h-full text-16px tracking-016px pl-3 pr-40px border border-2 border-socotec-gray-600 rounded-5px focus:outline-none", {
    "py-3": !small,
    "py-2": small
  })

  const iconClassName = classNames("absolute p-3 right-0", {
    "bg-socotec-white-100 border-1px border-solid border-socotec-gray-600 rounded-r-5px top-0 h-full flex justify-center": !small
  })

  return (
    <div className="flex flex-row items-center h-full relative">
      <input
        type="search"
        name="search"
        onChange={handleSearch}
        placeholder={placeholder}
        className={inputClassName}
      />
      <div className={iconClassName}>
        <img src={images.search} alt="Rechercher" />
      </div>
    </div>
  );
};

export default InputSearch;
