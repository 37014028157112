import React from "react"
import ReactModal from "react-modal";
import { useIntl } from "react-intl";
import { Site } from "../../../../models/Interfaces/Site";
import ModalHeader from "../../ModalHeader"
import ModalClose from "../../ModalClose"
import ModalContent from "../../ModalContent"
import images from "../../../../images";

interface Props {
  children: React.ReactNode;
  site: Site;
  isOpen: boolean;
  onClick: () => void;
}

const customStyles = {
  content: {
    top: 0,
    padding: 0,
    background: "#F5F7FC"
  },
  overlay: {
    zIndex: 9999
  }
};

const ModalMissionEnd = ({ children, site, isOpen, onClick }: Props) => {
  const intl = useIntl()

  const closeModal = <ModalClose onClick={onClick} />

  return (
    <ReactModal
      isOpen={isOpen}
      contentLabel="modal-endmission"
      appElement={document.getElementById("root")}
      style={customStyles}
    >
      <ModalHeader sticky title={site?.name} closeButton={closeModal}></ModalHeader>

      <ModalContent className="flex flex-col flex-grow">
        <div className="flex flex-col justify-center items-center flex-grow mb-8 font-roboto">
          <img src={images.transfertRapport} alt="Transferer" className="mb-4" />
          <span className="text-socotec-gray-100 text-18px font-bold mb-4">{intl.messages["ModalMissionEnd.content.title"]}</span>
          <span className="text-socotec-gray-100 text-15px text-center" style={{ maxWidth: "230px" }}>{intl.messages["ModalMissionEnd.content.text"]}</span>
        </div>
        {children}
      </ModalContent>
    </ReactModal>
  )
}

export default ModalMissionEnd