const socotec = require("../assets/images/socotec.png");
const location = require("../assets/images/location.svg");
const arrowRight = require("../assets/images/arrow-right.svg");
const arrowLeft = require("../assets/images/arrow-left.svg");
const arrowRightWhite = require("../assets/images/arrow-right_white.svg");
const start = require("../assets/images/start.svg");
const localisation = require("../assets/images/localisation.svg");
const localisation_pending = require("../assets/images/localisation_pending.svg");
const infos = require("../assets/images/infos.svg");
const contact = require("../assets/images/contact.svg");
const agence = require("../assets/images/agence.svg");
const eclipse = require("../assets/images/eclipse.svg");
const search = require("../assets/images/search.svg");
const close = require("../assets/images/close.svg");
const infosBulle = require("../assets/images/infos-bulle.svg");
const agenceBulle = require("../assets/images/agence-bulle.svg");
const locationBulle = require("../assets/images/location-bulle.svg");
const contactBulle = require("../assets/images/contact-bulle.svg");
const validated = require("../assets/images/validated.svg");
const retour = require("../assets/images/retour.svg");
const retourWhite = require("../assets/images/retour-white.svg");
const photo = require("../assets/images/photo.svg");
const echanger = require("../assets/images/echanger.svg");
const refresh = require("../assets/images/refresh.svg");
const picture = require("../assets/images/picture.png");
const stars = require("../assets/images/stars.svg");
const hide = require("../assets/images/hide.svg");
const show = require("../assets/images/show-eye.svg");
const noun = require("../assets/images/noun.svg");
const camera = require("../assets/images/camera.svg");
const gallery = require("../assets/images/gallerie.svg");
const ok = require("../assets/images/ok.svg");
const ko = require("../assets/images/ko.svg");
const valider = require("../assets/images/valider.svg");
const fermer = require("../assets/images/fermer.svg");
const intervenant = require("../assets/images/intervenant.svg");
const fiche = require("../assets/images/fiche.svg")
const casque = require("../assets/images/casque.svg")
const plus = require("../assets/images/plus.svg")
const plusWhite = require("../assets/images/plus-white.svg")
const transfertRapport = require("../assets/images/transfert-rapport.svg")
const done = require("../assets/images/done.svg")
const interrogation = require("../assets/images/interrogation.svg")
const retry = require("../assets/images/retry.svg")
const home = require("../assets/images/home.svg")

const images = {
  socotec,
  location,
  arrowRight,
  arrowLeft,
  arrowRightWhite,
  start,
  eclipse,
  localisation,
  localisation_pending,
  infos,
  contact,
  agence,
  search,
  close,
  infosBulle,
  agenceBulle,
  locationBulle,
  contactBulle,
  validated,
  retour,
  retourWhite,
  photo,
  echanger,
  refresh,
  picture,
  stars,
  hide,
  show,
  noun,
  camera,
  gallery,
  ok,
  ko,
  valider,
  fermer,
  intervenant,
  fiche,
  casque,
  plus,
  plusWhite,
  transfertRapport,
  done,
  interrogation,
  retry,
  home,
};

export default images;
